<template>
  <main>
    <one-column :fluid="!isDesktop">
      <template #title>
        <div :class="['col-12', { 'px-2': !isDesktop }]">
          <heading>{{ $t('loginPage.title') }}</heading>
        </div>
      </template>
      <div class="row login-content">
        <span class="signup-section">
          {{ $t('loginPage.description') }}
          <a
            class="main-link"
            :href="signUpPageUrl"
          >
            {{ $t('loginPage.signUp') }}
          </a>
        </span>
        <div class="social-login-wrap">
          <div class="login-layout social-wrapper-titles">
            <span class="social-or-login desktop">
              {{ $t('loginPage.loginWithEmail') }}
            </span>
          </div>
          <div class="login-layout social-wrapper">
            <login-form
              class="login-with-social"
              :show-forgot-password="true"
              @openForgotPassword="openForgotPassword"
              @fail="showErrorModal"
            />
          </div>
        </div>
      </div>

      <small-loader />
    </one-column>
  </main>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import LoginForm from 'components/ProfilePages/LoginForm'
  import ErrorNotification from 'components/Notifications/ErrorNotification'
  import ScrollUtils from 'utils/scrollUtils'
  import UrlParams from 'utils/UrlParams'
  import FormMixin from 'components/FormMixin'
  import gtmProfile from 'services/AirBaltic/GTM/Profile'

  export default {
    components: {
      LoginForm,
      Heading: () => import(/* webpackChunkName: "heading" */ 'components/Heading'),
      SmallLoader: () =>
        import(/* webpackChunkName: "small-loader" */ 'components/SmallLoader'),
      OneColumn: () =>
        import(/* webpackChunkName: "layout-one-column" */ 'layouts/templates/OneColumn')
    },
    mixins: [FormMixin],
    data() {
      return {
        withPins: false
      }
    },
    computed: {
      ...mapState('login', [
        'pageTitle',
        'description',
        'resetPasswordLinkText',
        'indexUrl',
        'signUpPageUrl',
        'profilePageUrl'
      ]),
      ...mapGetters('device', ['isDesktop'])
    },
    watch: {
      '$store.state.main.user.isUserLogged': function (newVal) {
        if (newVal && typeof this.indexUrl !== 'undefined') {
          window.location.href = this.indexUrl
        }
      }
    },
    mounted() {
      this.getEmailFromUrl()
      this.getLoginWithPinsFromUrl()
      this.openForgotPasswordModal()
      this.setIsLoginPage()
    },
    methods: {
      setIsLoginPage() {
        this.$store.commit('loginForm/setIsLoginPage', true)
      },
      openForgotPassword() {
        const ForgotPasswordForm = import(
          /* webpackChunkName: "white-forgot-password-form" */ 'components/LoginForm/White/ForgotPasswordForm.vue'
        )
        ForgotPasswordForm.then((result) => {
          this.$store.commit('main/setModalContent', result.default)
        })

        this.$store.dispatch('main/openModal', { root: true })
        this.$store.commit('main/setShowModalHeader', false)
        this.$store.commit('main/setShowModalHeaderWhite', false)
        this.$store.commit('main/setShowModalHeaderWhiteNav', false)
        this.$store.commit('main/setModalType', 'small')
        this.$store.commit('main/setModalClass', 'modal-white-small')
        this.$store.commit('main/setShowCloseIcon', true)
        this.$store.commit('main/setModalCloseFn', this.closeModal, { root: true })
        this.$store.dispatch('main/openModal', { root: true })
      },
      closeModal() {
        this.$store.dispatch('main/closeModal')
        ScrollUtils.enableScroll()
        this.$store.dispatch('loginForm/forgotPassword/resetForm')
      },
      showErrorModal(e) {
        gtmProfile.failedLoginAction('email', 'login_page', this.$t(e))
        if (e?.type === 'informal') {
          this.openFormNotification('fail', e)
          return
        }

        const props = {
          errors: {
            title: this.$t('loginPage.errorModalTitle'),
            content: this.$t(e)
          },
          close: this.closeErrorModal
        }

        this.$store.dispatch('main/openModal')
        this.$store.commit('main/setShowModalHeader', false)
        this.$store.commit('main/setShowModalHeaderWhite', false)
        this.$store.commit('main/setModalContent', ErrorNotification)
        this.$store.commit('main/setModalType', 'centered')
        this.$store.commit('main/setModalClass', 'modal-white-centered')
        this.$store.commit('main/setShowCloseIcon', true)
        this.$store.commit('main/setShowModalHeader', false)
        this.$store.commit('main/setModalCloseFn', this.closeErrorModal)
        this.$store.commit('main/setModalProps', props)

        if (this.isTablet) {
          ScrollUtils.enableScroll()
        }
      },
      closeErrorModal() {
        this.$store.dispatch('main/closeModal')
        ScrollUtils.enableScroll()
      },
      getEmailFromUrl() {
        const email = UrlParams.getUrlParam(window.location.href, 'email')
        if (email) {
          this.$store.commit('loginForm/setLogin', email)
        }
      },
      getLoginWithPinsFromUrl() {
        const withPins = UrlParams.getUrlParam(window.location.href, 'withPins')

        if (withPins !== null) {
          this.withPins = true
        }
      },
      openForgotPasswordModal() {
        const showModal = UrlParams.getUrlParam(window.location.href, 'forgotPassword')

        if (showModal !== null) {
          this.openForgotPassword()
        }
      }
    }
  }
</script>

<style
  lang="scss"
  scoped
  rel="stylesheet/scss"
>
  @import '~assets/styles/common.scss';
  @import '~assets/styles/bootstrap-grid.scss';

  ::v-deep .container {
    margin-bottom: 0 !important;

    @include bp-mf-desktop {
      margin-bottom: 86px !important;
    }
  }

  .login-content {
    padding-bottom: 32px;
    background: $blue-bg;

    @include bp-mf-tablet {
      padding-bottom: 40px;
    }

    @include bp-mf-tablet {
      padding-bottom: 72px;
    }
  }

  .signup-section {
    display: block;
    width: 100%;
    padding: 16px 20px;
    margin: 0;
    font-size: em(14);
    line-height: 18px;
    color: $blue-dark;
    text-align: center;
    letter-spacing: 0;
    border-bottom: 1px solid $blue-dividers;

    @include bp-mf-tablet {
      padding: 24px 10px 20px !important;
      font-size: em(16);
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;

    @include bp-mf-desktop {
      flex-direction: row;
      justify-content: space-between;
    }

    .block {
      width: 100%;

      @include bp-mf-desktop {
        width: 50%;
      }
    }
  }

  .social-or-login {
    display: block;
    font-size: em(14);
    line-height: 18px;
    color: $blue-light;
    text-align: center;
    letter-spacing: 0;
    margin-bottom: 16px;

    @include bp-mf-desktop {
      text-align: left;
      margin-bottom: 0;
    }

    &.login-social {
      width: 265px;
      text-align: left;
    }

    &.desktop {
      display: none;

      @include bp-mf-desktop {
        margin-bottom: 0;
        display: block;
      }
    }

    &.mobile {
      @include bp-mf-desktop {
        display: none;
      }
    }
  }

  .login-main-block {
    padding: 0 20px;

    @include bp-mf-tablet {
      padding: 0 10px;
      margin-top: 12px;
    }

    @include bp-mf-desktop {
      padding: 0 5px 0 0;
      margin-top: 12px;
      margin-left: 10px;
    }
  }

  .login {
    &-layout {
      @include bp-mf-desktop {
        display: flex;
        justify-content: center;
      }
    }

    &-with-social {
      @include bp-mf-desktop {
        max-width: 320px !important;
      }

      /deep/ .main-link.forgot {
        margin-top: 12px !important;
      }
    }
  }

  .social {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @include bp-mf-tablet {
      justify-content: center;
    }

    @include bp-mf-desktop {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
    }

    &-wrapper {
      margin-top: 16px;

      @include bp-mf-desktop {
        margin-top: 0;
      }
    }

    &-wrapper-titles {
      display: none;

      margin-bottom: 28px;
      margin-top: 36px;

      @include bp-mf-desktop {
        display: grid;
      }
    }

    &-login-wrap {
      width: 100%;
      padding: 0 16px;

      @include bp-mf-desktop {
        padding: 0;
      }
    }

    &-title {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
</style>
