<template>
  <div class="search-result-item">
    <a
      :href="url"
      class="title"
    >
      {{ title }}
    </a>
    <div class="url">
      {{ url }}
    </div>
    <div
      v-dompurify-html="description"
      class="description"
    />
  </div>
</template>

<script>
  export default {
    props: {
      id: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        default: '',
        required: false
      },
      url: {
        type: String,
        required: true
      }
    }
  }
</script>

<style
  lang="scss"
  scoped
  rel="stylesheet/scss"
>
  @import '~assets/styles/common.scss';

  .search-result-item {
    padding: 16px 0 12px;
    border-bottom: 1px solid $grey-border;

    @include bp-mobile {
      padding: 18px 0 15px;
    }

    .title {
      font-size: em(18);
      font-weight: 500;
      text-decoration: none;
      color: $blue-medium;

      @include bp-mobile {
        font-size: em(16);
        line-height: 22px;
      }

      &:hover {
        color: $blue-light;
      }
    }

    .url {
      margin: 10px 0;
      width: 303px;
      @include text-overflow();
      color: $grey;

      @include bp-mobile {
        margin: 10px 0 11px;
        width: 100%;
      }
    }

    .description {
      display: block;
      font-size: em(16);
      line-height: 24px;

      @include bp-mobile {
        font-size: em(14);
        line-height: 20px;
      }
    }

    /deep/ em {
      font-style: normal;
      font-weight: 500;
    }
  }
</style>
