<template>
  <div class="footer-legal">
    <ul class="legal-items">
      <li
        v-for="(item, index) in items"
        :key="index"
      >
        <a
          v-if="item.href"
          :href="item.href"
        >
          {{ $t(`footerWide.bottom.${index}.text`) }}
        </a>
      </li>

      <li>
        <a
          :href="cookieLink"
        >
          {{ $t(`footerWide.bottom.cookieList.text`) }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'FooterLegal',
    props: {
      items: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      ...mapState({
        cookieTypes: (state) => state.main.gdprCookie,
        selectedLang: (state) => state.main.selectedLang
      }),

      cookieLink() {
        return this.selectedLang === 'lv' ? '/lv/sikdatnu-politika' : '/en/cookie-policy'
      }
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .footer-legal {
    margin: 0 0 24px 0;

    :last-child {
      margin-bottom: 0;
    }

    @include bp-mf-desktop {
      margin: 0;
      width: 55%;
    }

    @include bp-desktop {
      width: 50%;
    }
  }

  .copyright {
    @include defaultFontValues($blue-dark, 14, 20);
    padding: 10px 0;
    display: inline-block;
    width: 100%;
    text-decoration: none;

    @include bp-mf-desktop {
      width: auto;
      margin-right: 54px;
    }
  }

  .legal-items {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin-top: 9px;

    @include bp-mf-tablet {
      width: 100%;
      display: flex;
      max-width: unset;
    }

    @include bp-mf-desktop {
      display: inline-block;
      flex-direction: row;
      width: auto;
    }

    li {
      display: inline-block;
      position: relative;
      margin-bottom: 20px;

      &.hide {
        display: none;
      }

      &:not(:last-child) {
        margin-right: 15px;

        @include bp-mf-tablet {
          margin-right: 35px;
        }
      }
    }

    a {
      @include defaultFontValues($blue-dark, 14, 20);
      padding: 10px 0;
      text-decoration: none;
    }
  }
</style>
