<template>
  <footer>
    <div
      v-if="show"
      class="wrap"
    >
      <div class="footer-links">
        <div
          v-for="link in footerLinks.items"
          :key="link.key"
          class="footer-submenu-block"
        >
          <span
            :class="{ active: !isDesktop && link.active }"
            class="footer-submenu-title"
            @click="openMenu(link.key)"
          >
            <a
              v-if="isDesktop"
              :href="link.href"
            >
              {{ link.label }}
            </a>
            <span v-else>
              {{ link.label }}
            </span>
          </span>

          <ul class="footer-submenu-list">
            <li
              v-for="item in link.childs"
              :key="item.key"
            >
              <a
                :class="{ external: item.linkTarget === '_blank' }"
                :href="item.href"
                :target="item.linkTarget"
              >
                {{ item.label }}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <subscribe-popup
        v-if="showSubscribePopup"
        :closed-popup-cookie-name="closedPopupCookie"
        :subscribe-source="'index-popup'"
        :subscribed-popup-cookie-name="subscribedPopupCookie"
        :viewed-popup-cookie-name="viewedPopupCookie"
        @subscribed="subscribeCallback"
      />

      <div class="item-group">
        <footer-legal :items="footerLinks.legalLinks" />
        <footer-payments v-if="!isDesktop" />
        <footer-disclaimers />
      </div>
      <div class="item-group">
        <footer-payments v-if="isDesktop" />
      </div>
    </div>

    <div
      v-if="show"
      class="group last"
    >
      <div class="wrap">
        <div class="item-group">
          <div class="copyright-contacts">
            <a
              v-if="footerLinks.contactUs"
              v-dompurify-html="$t('footerWide.contactUs.label')"
              :href="footerLinks.contactUs.href"
              class="contact-us"
            />
            <a
              v-dompurify-html="$t('footerWide.copyright')"
              :href="`/${selectedLang}/bottom_menu/airbaltic-2018`"
              class="copyright"
            />
          </div>
          <footer-social :social="social" />
        </div>
      </div>
    </div>

    <template v-if="show && jsonld">
      <component
        :is="'script'"
        type="application/ld+json"
        v-html="jsonld"
      />
    </template>
  </footer>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import cloneDeep from 'lodash-es/cloneDeep'
  import FooterSocial from 'containers/Footer/FooterFacelift/Social'
  import FooterLegal from 'containers/Footer/FooterFacelift/Legal'
  import FooterPayments from 'containers/Footer/FooterFacelift/Payments'
  import FooterDisclaimers from 'containers/Footer/FooterFacelift/Disclaimers'
  import CookieUtils from 'utils/CookieUtils'

  export default {
    name: 'FooterWide',
    components: {
      FooterSocial,
      FooterLegal,
      FooterPayments,
      FooterDisclaimers,
      'subscribe-popup': () =>
        import(
          /* webpackChunkName: "subscribe-popup" */ 'containers/Pages/IndexPage/SubscribePopup'
        )
    },
    data() {
      return {
        social: ['twitter', 'facebook', 'youtube', 'instagram', 'tiktok', 'linkedin'],
        subscribedPopupCookie: 'subscribePopupHide',
        closedPopupCookie: 'subscribePopupClosed',
        viewedPopupCookie: 'subscribePopupViewed',
        hasSubscribed: false,
        show: true
      }
    },
    computed: {
      ...mapState('footer', ['footerLinks', 'subscribePopupDisabled', 'footerSemantics']),
      ...mapGetters('device', ['isMobile', 'isDesktop']),
      ...mapState('main', ['hideCookieAlert', 'pageType', 'selectedLang']),
      ...mapState('search', ['searchPageUrl']),
      isNewsletterPage() {
        return this.pageType === 'newsletter'
      },
      showSubscribePopup() {
        const cookieValue = parseInt(CookieUtils.getCookie(this.subscribedPopupCookie))
        return (
          !this.subscribePopupDisabled &&
          !this.isNewsletterPage &&
          !cookieValue &&
          this.isDesktop &&
          !this.hasSubscribed
        )
      },
      jsonld() {
        const sameAs = this.social.map((item) => this.$t(`footerWide.social.${item}`))

        if (Object.keys(this.footerSemantics).length === 0) {
          return null
        }

        return {
          '@context': 'http://schema.org',
          '@graph': [
            {
              '@context': 'http://schema.org',
              '@type': 'WebSite',
              url: window.location.origin,
              potentialAction: {
                '@type': 'SearchAction',
                target:
                  window.location.origin +
                  this.footerSemantics.searchPageUrl +
                  '?search={search_term_string}',
                'query-input': 'required name=search_term_string'
              }
            },
            {
              '@context': 'http://schema.org',
              '@type': 'Organization',
              url: window.location.origin,
              logo: window.location.origin + this.footerSemantics.logo,
              name: 'AirBaltic',
              email: this.footerSemantics.email,
              address: {
                '@type': 'PostalAddress',
                streetAddress: this.footerSemantics.address,
                addressCountry: 'LV'
              },
              contactPoint: [
                {
                  '@type': 'ContactPoint',
                  telephone: this.footerSemantics.phone,
                  contactType: 'customer service'
                }
              ],
              sameAs
            }
          ]
        }
      }
    },
    created() {
      if (this.isMobile) {
        const param = CookieUtils.getCookie('btMobileApp')

        this.show = param === ''
      }
    },
    methods: {
      openMenu(key) {
        if (this.isDesktop) {
          return false
        }

        const newLinks = cloneDeep(this.footerLinks)

        newLinks.items.map((link) => {
          link.active = link.key === key ? !link.active : false
        })

        this.$store.commit('footer/setFooterLegalLinks', newLinks, { root: true })
      },

      subscribeCallback() {
        this.hasSubscribed = true
      }
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  footer {
    position: relative;
    width: 100%;
    border-top: 1px solid $blue-border;

    @include bp-mf-desktop {
      background: $blue-bg-css;
    }

    .wrap {
      background: $blue-bg-css;
      padding: 0 24px;

      @include bp-mf-tablet {
        max-width: $tablet-wide-width;
      }

      @include bp-mf-desktop {
        max-width: $desktop-wide-width;
        padding: 0;
      }

      @include bp-desktop-small {
        max-width: $tablet-wide-width;
      }
    }

    .group.last {
      background: $blue-dark;

      @include bp-mf-desktop {
        margin-top: 34px;
        padding-top: 14px;
        border-top: 1px solid $blue-border;
        padding-bottom: 20px;
      }

      .wrap {
        background: inherit;
      }

      .item-group {
        display: flex;
        flex-direction: column;

        @include bp-mf-desktop {
          flex-direction: row;

          .disclaimers {
            width: 70%;
          }
        }

        .copyright-contacts {
          display: flex;
          align-items: center;
          flex-direction: column;
          order: 2;

          @include bp-mf-desktop {
            flex-direction: row;
            order: unset;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }

          .copyright {
            color: $grey-border-light;
            font-size: rem(13, 16);
            text-decoration: none;
            margin-bottom: 32px;

            @include bp-mf-tablet {
              margin-bottom: 24px;
            }

            @include bp-mf-desktop {
              margin-bottom: 0;
            }
          }

          .contact-us {
            color: white;
            font-size: rem(13, 16);
            padding-bottom: 16px;

            @include bp-mf-desktop {
              order: 2;
              padding: 0;
              text-decoration: none;
            }
          }
        }
      }
    }

    .footer-links {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      border-bottom: 1px solid $blue-border;
      padding: 16px 0;

      @include bp-mf-desktop {
        margin: 0;
        padding: 35px 0 30px;
        flex-direction: row;
        border: none;
      }
    }

    .footer-submenu-block {
      @include bp-mf-desktop {
        width: calc(25% - 15px);
        padding-left: 0;
      }
    }

    .footer-submenu-title {
      display: block;
      position: relative;
      margin: 8px 0 0 0;
      @include defaultFontValues($blue-dark, 16, 40, 400);
      padding-right: 46px;

      @include bp-mf-desktop {
        @include defaultFontValues($blue-dark, 16, 24, 500);
        margin-bottom: 15px;
        display: inline-block;
        padding: 0;
        text-transform: initial;

        a {
          color: $blue-dark;
          text-decoration: none;
        }
      }

      &:after {
        @include icon-font-thin;
        content: $icon-arrow-right-thin-medium;
        transform: rotate(90deg);
        position: absolute;
        top: calc(50% - 20px);
        right: 12px;
        font-size: rem(11, 16);
        line-height: rem(40, 16);
        text-align: center;
        opacity: 0.5;

        @include bp-mf-desktop {
          display: none;
        }
      }

      &.active {
        &:before {
          display: none;
        }

        &:after {
          transform: rotate(270deg);
          opacity: 1;
        }

        + .footer-submenu-list {
          display: block;
          margin-bottom: 16px !important;
        }
      }
    }
  }

  .footer-submenu-list {
    display: none;
    width: 100%;

    @include bp-mf-desktop {
      width: calc(100% + 16px);
      display: block;
      max-width: 224px;
    }

    li {
      list-style: none;

      &:first-child {
        ::v-deep a {
          padding-top: 16px;
        }
      }

      ::v-deep a {
        @include defaultFontValues($blue-dark, 14, 24);
        text-decoration: none;
        display: block;
        padding-bottom: 8px;
        padding-left: 15px;

        @include bp-mf-desktop {
          @include defaultFontValues($blue-dark, 14, 22);
          padding-left: 0;
          display: inline;
        }

        &:hover {
          color: $blue-light;
        }

        &.external {
          &:after {
            content: $icon-external-link-facelift;
            @include icon-font-thin();
            vertical-align: top;
            margin-left: -4px;
            margin-top: 2px;
            font-size: rem(8, 16);
            line-height: normal;
            color: $grey;
          }
        }
      }

      @include bp-mf-desktop {
        padding-bottom: 8px;
      }
    }
  }

  .item-group {
    @include bp-mf-desktop {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
</style>
