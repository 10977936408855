const SnippetTemplate = {
  state: {
    sections: {},
    pageTitle: '',
    pageTitlePosition: '',
    pageIntName: '',
  },
  mutations: {
    setSections (state, sections) {
      state.sections = sections
    },
    setPageTitle (state, title) {
      state.pageTitle = title
    },
    setPageDescription (state, title) {
      state.pageDescription = title
    },
    setPageTitlePosition (state, position) {
      state.pageTitlePosition = position
    },
    setPageIntName (state, intName) {
      state.pageIntName = intName
    }
  },
  namespaced: true
}

export default SnippetTemplate
