const defaultState = {
  pageTitle: '',
  subTitle: '',
  faqBlock: [],
  helpBlock: [],
  otherTopics: [],
  otherInquiries: [],
  forms: [],
  representatives: [],
  countries: []
}

const HelpAndContact = {
  namespaced: true,
  state: defaultState,
  actions: {
    setPageData({ commit }, data) {
      commit('setPageTitle', data.pageTitle)
      commit('setSubTitle', data.subTitle)
      commit('setFaqBlock', data.faqBlock)
      commit('setHelpBlock', data.helpBlock)
      commit('setOtherTopicsBlock', data.otherTopics)
      commit('setOtherInquiriesBlock', data.otherInquiries)
      commit('setFormBlock', data.forms)
      commit('setRepresentatives', data.representatives)
    },
    setCountries({ commit }, value) {
      const countries = Object.values(value).map(({ code, name }) => {
        return {
          key: code,
          value: code,
          title: name
        }
      })

      commit('setCountries', countries)
    }
  },
  mutations: {
    setPageTitle(state, value) {
      state.pageTitle = value
    },
    setSubTitle(state, value) {
      state.subTitle = value
    },
    setFaqBlock(state, value) {
      state.faqBlock = value
    },
    setHelpBlock(state, value) {
      state.helpBlock = value
    },
    setOtherTopicsBlock(state, value) {
      state.otherTopics = value
    },
    setFormBlock(state, value) {
      state.forms = value
    },
    setRepresentatives(state, value) {
      state.representatives = value
    },
    setOtherInquiriesBlock(state, value) {
      const data = {
        title: value.title,
        items: []
      }

      if (value.items && value.items.length) {
        value.items.forEach((item) => {
          data.items.push({
            open: false,
            ...item
          })
        })
      }

      state.otherInquiries = data
    },
    setOtherInquiriesItems(state, value) {
      state.otherInquiries.items = value
    },
    setCountries(state, value) {
      state.countries = value
    }
  }
}

export default HelpAndContact
