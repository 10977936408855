import Api from 'services/AirBaltic/Api'
import Pimcore from 'services/AirBaltic/Pimcore'
import Generic from 'models/SelfService/Generic'
import Passenger from 'models/SelfService/Passenger'
import VolDateChangeModel from 'models/SelfService/VolDateChange'

import get from 'lodash-es/get'
import moment from 'moment'
import head from 'lodash-es/head'
import isUndefined from 'lodash-es/isUndefined'
import isNull from 'lodash-es/isNull'
import find from 'lodash-es/find'
import Vue from 'vue'
// import axios from 'axios'
import has from 'lodash-es/has'
import every from 'lodash-es/every'
import flatMap from 'lodash-es/flatMap'
import cloneDeep from 'lodash/cloneDeep'
import ErrorHandler from 'utils/ErrorHandler'
import CookieUtils from 'utils/CookieUtils'

const FLOWN = 'flown'
const BUSINESS = 'business'
const OPERATED_BY_BT = 'BT'
const DEFAULT_BAG_WEIGHT_KG = 23
const DEFAULT_BAG_WEIGHT_POUNDS = 50
const BAG_WEIGHT_LIMIT_KG = 32
const BAG_WEIGHT_LIMIT_POUNDS = 71
const UNIT_KG = 'K'
const UNIT_POUNDS = 'L'
const UNIT_COUNT = 'N'
const UNIT_WEIGHT = 'W'
const passengers = []
let flights = []
const openBlocks = []
const origDestData = []

class SelfService {
  constructor(store, selectedLang, origDestData) {
    this.added = this.translateKey('selfService.added')
    this.notAdded = this.translateKey('selfService.notAdded')
    this.pnr = ''
    this.surname = ''
    this.rip = false
    this.servicesList = []
    this.selectedLang = selectedLang
    this.checkDCHGCookie = false

    if (!isUndefined(store)) {
      this.rip = store.ripTabs.includes('MMB')
      this.servicesList = store.mapping
      this.checkDCHGCookie = store.checkDCHGCookie
    }

    if (origDestData) {
      this.origDestData = origDestData
    }
  }

  getPnrData(pnr, surname, isTicket) {
    this.pnr = pnr
    this.surname = surname
    let endpoint = '/mmb/pnr/retrieve/'
    let key = 'pnrRL'

    if (isTicket) {
      endpoint = '/mmb/pnr/retrieve/ticket/'
      key = 'ticketNumber'
    }

    return new Api().post(endpoint, { [`${key}`]: pnr, surname, lang: this.selectedLang })
  }

  getPnrDataByToken(token) {
    let endpoint = '/mmb/pnr/retrieve/byToken/'
    let key = 'token'

    return new Api().post(endpoint, { [`${key}`]: token, lang: this.selectedLang })
  }

  getFlightOrig(segmentData) {
    const flightOrigin = this.getFlightOrigin(segmentData)
    return `${flightOrigin.city} (${flightOrigin.code})`
  }

  getFlightDest(segmentData) {
    const flightDestin = this.getFlightDestin(segmentData)
    return `${flightDestin.city} (${flightDestin.code})`
  }

  getDirections(data) {
    const directions = {}

    const directionData = get(data, 'directions', [])
    const segments = get(data, 'segments', [])

    directionData.forEach((direction) => {
      const singleDestination = {
        data: direction,
        changes: [],
        segments: []
      }

      const directionSegments = get(direction, 'segments', [])
      const segmentLength = directionSegments.length - 1

      const segmentsData = directionSegments.map((segmentId, index) => {
        const segment = segments.find((item) => item.id === segmentId)

        if (index === 0) {
          singleDestination.origin = this.getFlightOrig(segment)
        }

        if (index === segmentLength) {
          singleDestination.destin = this.getFlightDest(segment)
        }

        return this.parseFlight(segment, null, directionData, null)
      })

      const hasChanges = segmentsData.some((segment) => segment.automaticFlightOffer)

      singleDestination.segments = segmentsData

      if (hasChanges) {
        const changedData = this.getUpdatedSegments(segmentsData, directionData)

        singleDestination.changes = changedData.changes
        singleDestination.nextDay = changedData.nextDay
        singleDestination.diffOrig = changedData.diffOrig
        singleDestination.diffDest = changedData.diffDest
        singleDestination.diffConn = changedData.diffConn
        singleDestination.data.hasChanges = hasChanges
      }

      directions[direction.id] = singleDestination
    })

    return directions
  }

  getLastAltFlight(segment) {
    if (segment.status === 'UN') {
      let children = segment.automaticFlightOffer?.segments
      if (children?.length > 1) {
        return children
      } else if (children?.length === 1) {
        return this.getLastAltFlight(segment.automaticFlightOffer.segments[0])
      }

      return []
    } else {
      return [segment]
    }
  }
  getUpdatedSegments(segmentsData, directionData) {
    let nextDay = false
    let diffOrig = {
      status: false,
      prev: '',
      current: ''
    }
    let diffDest = {
      status: false,
      prev: '',
      current: ''
    }
    let diffConn = {
      status: false,
      prev: '',
      current: ''
    }

    const changes = segmentsData.flatMap((segment) => {
      if (!segment?.automaticFlightOffer && !segment?.timeChangeMinutes) {
        return segment
      }

      const segments = []

      if (segment?.timeChangeMinutes) {
        const changedSegment = { ...segment }
        changedSegment.updated = true

        segments.push(changedSegment)
      }

      if (segment?.automaticFlightOffer) {
        const offerCount = segment.automaticFlightOffer.segments.length
        segment.automaticFlightOffer.segments.forEach((altSegment, index) => {
          let altSegments = this.getLastAltFlight(altSegment)

          altSegments.forEach((flight) => {
            const changedSegment = this.parseFlight(flight, null, directionData, null)

            if (!nextDay) {
              nextDay =
                !moment(changedSegment.departure).isSame(segment.departure, 'day') ||
                !moment(changedSegment.arrival).isSame(segment.arrival, 'day')
            }

            if (index === 0) {
              if (!diffOrig.status) {
                diffOrig = {
                  status: segment.orig !== changedSegment.orig,
                  current: changedSegment.origAptWithCode,
                  prev: segment.origAptWithCode
                }
              }
            }

            if (index === offerCount - 1) {
              if (!diffDest.status) {
                diffDest = {
                  status: segment.dest !== changedSegment.dest,
                  current: changedSegment.destAptWithCode,
                  prev: segment.destAptWithCode
                }
              }
            }

            if (!diffConn.status && index !== 0) {
              const prevIndex = index - 1
              diffConn = {
                status: changedSegment.orig !== segments[prevIndex].dest,
                current: changedSegment.origAptWithCode,
                prev: segments[prevIndex].destAptWithCode
              }
            }

            segments.push({
              ...changedSegment,
              updated: true,
              nextDay,
              diffOrig,
              diffDest,
              diffConn
            })
          })
        })
      }

      return segments
    })

    return {
      changes,
      nextDay,
      diffOrig,
      diffDest,
      diffConn
    }
  }

  getSegments(data) {
    const segments = get(data, 'segments', [])
    const firstSegment = segments.length ? head(segments).id : null
    const directions = get(data, 'directions', [])

    segments.map((segment, key) => {
      const flight = this.parseFlight(segment, key, directions, firstSegment)
      if (segments[key + 1] && segments[key + 1].isPrebooked) {
        flight.isNextSegmentPrebooked = true
      }
      flights.push(flight)
    })

    return flights
  }

  getUnitTranslation(unit) {
    let key = unit
    if (unit === UNIT_KG) {
      key = 'reusable.kilograms.short'
    } else if (unit === UNIT_POUNDS) {
      key = 'reusable.pounds.short'
    }

    return this.translateKey(key)
  }

  getSpeqType(type) {
    return this.translateKey(`reusable.speq.${type.toLowerCase()}`)
  }

  getMealType(type) {
    return this.translateKey(`reusable.meals.${type}`)
  }

  getType(type) {
    return this.translateKey(`selfService.type.${type}`)
  }

  getCount(bag) {
    const quantityCode = get(bag, 'quantityCode', null)
    let count = 0
    if (quantityCode === UNIT_WEIGHT) {
      count = 1
    } else if (quantityCode === UNIT_COUNT) {
      count = get(bag, 'baggageAllowance', 0)
    }

    return count
  }

  getUnit(bag) {
    let unit = get(bag, 'unitQualifier', UNIT_KG)

    if (unit === '') {
      return UNIT_KG
    }

    return unit
  }

  getWeight(bag, unit) {
    const quantityCode = get(bag, 'quantityCode', UNIT_COUNT)
    let weight = 0
    let bagWeight = DEFAULT_BAG_WEIGHT_KG

    if (unit === UNIT_POUNDS) {
      bagWeight = DEFAULT_BAG_WEIGHT_POUNDS
    }

    if (quantityCode === UNIT_WEIGHT) {
      weight = get(bag, 'baggageAllowance', bagWeight)
    } else if (quantityCode === UNIT_COUNT) {
      weight = bagWeight
    }

    return weight
  }

  setDefaultPassenger(passenger, name, segmentId) {
    let text = this.notAdded
    let businessSeat = false

    if (name === 'checkinStatuses') {
      text = this.translateKey('reusable.notCheckedIn')
    }

    if (name === 'seatRequest') {
      const isBusinessSegment = this.isBusinessSegment(segmentId)
      const isBTOperatedSegment = this.isBTOperatedSegment(segmentId)

      if (isBusinessSegment && isBTOperatedSegment) {
        text = this.translateKey('selfService.businessSeat')
        businessSeat = true
      }
    }

    return {
      serviceName: name,
      id: passenger.id,
      initials: passenger.initials,
      name: passenger.name,
      type: passenger.type,
      items: [],
      hasInfants: passenger.hasInfants,
      infants: passenger.infants,
      button: null,
      businessSeat,
      text
    }
  }

  parseData(data, pnrRetrieveToken = '') {
    const generic = new Generic()
    const directions = get(data, 'directions', [])
    const hasScheduleChange = directions.some(
      (direction) => direction?.scheduleChange === 'BT'
    )

    const checkinStatuses = get(data, 'checkinStatuses', null)

    let flightDirections = []

    const flightList = this.getSegments(data)

    if (hasScheduleChange || data.voluntaryDateChangePossible) {
      flightDirections = this.getDirections(data)
    }

    get(data, 'passengers', []).map((passenger) => {
      passengers.push(new Passenger(passenger))
    })

    const insurance = data.serviceData.insurance
    const services = this.getServicesFromWS(data, [])
    const oldSixtAdded = data.services.filter((item) => item.service === 'SIXT')
    const sixtAdded = data.serviceData?.cars.filter((item) => item.carsType === 'SIXT')
    const safServices = data.services.filter(
      (item) => item.service === 'sustainableAviationFuel' && item.type
    )

    const genericData = {
      ...generic,
      pnr: get(data, 'pnrRl', null),
      pnrRetrieveToken: pnrRetrieveToken,
      errors: get(data, 'errors', null),
      surname: get(data, 'surname', null),
      retrieveInIbe: get(data, 'retrieveInIbe', null),
      corporate: get(data, 'corporate', false),
      seamen: get(data, 'seamen', false),
      generalEmail: get(data, 'generalEmail', null),
      generalPhoneNumber: get(data, 'generalPhoneNumber', null),
      creationOfficeType: get(data, 'creationOfficeType', null),
      refundToken: get(data, 'refundToken', null),
      refundTypes: get(data, 'refundTypes', []),
      isIdTravel: get(data, 'idTravel', false),
      cssTicketType: get(data, 'cssTicketType', null),
      totalDuration: 0,
      sixtAdded: sixtAdded.length > 0,
      sixtData: sixtAdded.length > 0 ? sixtAdded[0] : [],
      oldSixtAdded: oldSixtAdded.length > 0,
      sustainableAviationFuel:
        get(data, 'serviceData.sustainableAviationFuel', false) && !safServices.length,
      voluntaryDateChangePossible: get(data, 'voluntaryDateChangePossible', false),
      checkinStatuses
    }

    return {
      generic: genericData,
      passengers,
      services,
      segments: flightList,
      changes: flightDirections,
      insurance
    }
  }

  getServicesFromWS(data, items) {
    const allowedCabinBaggage = get(data, 'allowedCabinBaggage', [])
    items = this.getData(allowedCabinBaggage, items, 'allowedCabinBaggage')

    const allowedCheckedBaggage = get(data, 'allowedCheckedBaggage', [])
    items = this.getData(allowedCheckedBaggage, items, 'allowedCheckedBaggage')

    let services = get(data, 'services', [])
    const specialMeals = get(data.serviceData, 'specialMeal', [])
    specialMeals.forEach((specialMeal) => {
      specialMeal.meals.forEach((meal) => {
        services.push({
          service: 'mealRequest',
          passengerId: specialMeal.passengerId,
          segmentId: specialMeal.segmentId,
          status: specialMeal.status,
          type: meal.mealCode,
          quantity: meal.quantity
        })
      })
    })
    services = services.filter(
      (service) => !(service.service === 'mealRequest' && service.type === 'SPML')
    )

    items = this.getData(services, items)

    const checkinStatuses = get(data, 'checkinStatuses', [])
    items = this.getData(checkinStatuses, items, 'checkinStatuses')

    const offers = get(data, 'offers', [])
    items = this.getData(offers, items, null, true, true)

    const dcsSeats = get(data, 'dcsSeats', [])
    items = this.getData(dcsSeats, items, 'seatRequest')

    items.forEach((segment, segmentId) => {
      this.parseSegmentServices(segment, segmentId)
    })

    return items
  }

  checkIfSegmentFlown(segmentId) {
    const foundFlight = this.getSegment(segmentId)

    return foundFlight?.status === FLOWN
  }

  getData(data, items, name = null, excludeFlown = false, isOffers = false) {
    data.forEach((item) => {
      let service = null
      let segment = null
      let nameChanged = false

      const segmentId = item.segmentId
      const passengerId = item.passengerId

      if (excludeFlown) {
        this.checkIfSegmentFlown(segmentId)
      }

      if (!isUndefined(item.service) && !isNull(item.service)) {
        name = item.service
      }

      if (name === 'nameChangeWarranty') {
        const pax = this.findPassenger(item.passengerId)

        if (!isUndefined(pax)) {
          pax.services[`${name}`] = item.status
        }
      }

      if (name === 'allowedCabinBaggage') {
        name = 'cabinBaggage'
      }

      if (name === 'allowedCheckedBaggage') {
        name = 'checkedBaggage'
      }

      if (name === 'mobileCkiInvite') {
        const pax = this.findPassenger(item.passengerId)

        if (!isUndefined(pax)) {
          pax.services[`${name}`] = {
            text: this.translateKey('selfService.invitationAdded')
          }
          return
        }
      }

      if (['petInCabin', 'animalInHold'].includes(name)) {
        name = 'pets'
      }

      if (['checkinFee', 'prioCheckin'].includes(name)) {
        name = 'airportCheckinAndBag'
      }

      if (
        [
          'wheelchair',
          'specialService',
          'blindAssistance',
          'deafAssistance',
          'oxygenOwnDevice',
          'oxygenBTDevice',
          'serviceAnimal'
        ].includes(name)
      ) {
        name = 'medicalAssistance'
      }

      if (isUndefined(items[`${segmentId}`])) {
        items[`${segmentId}`] = {}
      }

      segment = items[`${segmentId}`]
      let segmentName = name

      if (name === 'fragileItem') {
        segmentName = 'cabinBaggage'
      }

      if (['oversizedBaggageCharge'].includes(name)) {
        segmentName = 'checkedBaggage'
      }

      if (['extraSeatForComfort', 'extraSeatForBaggage'].includes(name)) {
        segmentName = 'seatRequest'
        nameChanged = true
      }

      if (isUndefined(segment[`${segmentName}`])) {
        segment[`${segmentName}`] = {
          header: {},
          notifications: [],
          passengers: [],
          all: false,
          empty: false
        }
      }

      service = segment[`${segmentName}`]

      if (segmentId === item.segmentId && passengerId === item.passengerId) {
        service.header = this.getHeaderData(segmentName, segmentId, nameChanged)
        service.passengers = this.createPaxInNotExists(
          service.passengers,
          passengerId,
          segmentName,
          segmentId
        )

        let foundPassenger = find(
          service.passengers,
          (passenger) => passenger.id === passengerId
        )

        if (foundPassenger) {
          foundPassenger = this.addDataToPax(
            name,
            foundPassenger,
            item,
            isOffers,
            nameChanged
          )
          this.addSeatNotification(service, foundPassenger, name, item)
        }
      }
    })

    return items
  }

  addSeatNotification(service, passenger, name, item) {
    const hasSeats = passenger.items.filter(
      (pax) => has(pax, 'dcsSeatNumber') && has(pax, 'seatNumber')
    )
    const diffSeats = hasSeats.dcsSeatNumber !== hasSeats.seatNumber
    const notification = this.getNotifications(name, item, diffSeats)
    const hasItem = find(service.notifications, notification)

    if (notification && !hasItem) {
      if (notification.type === 'unconfirmed') {
        service.notifications[0] = notification
      } else {
        service.notifications[1] = notification
      }
    }
  }

  parseSegmentServices(segment, segmentId) {
    Object.entries(segment).forEach(([name, service]) => {
      const servicePassengers = service.passengers

      this.updateHeaderTexts(name, service)

      const isBusinessSegment = this.isBusinessSegment(segmentId)

      const excluded = ['cabinBaggage', 'checkinStatuses']

      if (isBusinessSegment) {
        excluded.push('seatRequest')
      }

      if (servicePassengers.length === 1 || excluded.includes(name)) {
        return
      }

      this.allPassengersCheck(name, service)
    })
  }

  updateHeaderTexts(name, service) {
    if (name === 'checkinStatuses') {
      const hasStatuses = []
      service.passengers.forEach((passenger) => {
        const items = get(passenger, 'items', [])
        if (items.length > 0) {
          items.map((status) => hasStatuses.push(status.value))
        }
      })

      if (every(hasStatuses, (item) => item === 'CAC')) {
        service.header.name = this.translateKey(
          'selfService.services.checkinStatuses.titleCAC'
        )
        service.header.description = this.translateKey(
          'selfService.services.checkinStatuses.descriptionCAC'
        )
      }
    }
  }

  allPassengersCheck(name, service) {
    const data = {
      infants: [],
      name: this.translateKey('reusable.allPassengers')
    }
    const hasItems = []
    let excludeText = false

    service.passengers.forEach((passenger) => {
      data.button = get(passenger, 'button', null)
      hasItems.push(get(passenger, 'items', []).length === 0)

      if (['heavyBaggageFee'].includes(name) && passenger.items.length > 0) {
        data.text = head(passenger.items).text
        if (!excludeText) {
          excludeText = true
        }
      }
    })

    const allFilled = every(hasItems, (item) => item === false)
    const allEmpty = every(hasItems, (item) => item === true)
    const allPassengers = hasItems.length === passengers.length || hasItems.length === 0

    if (!allPassengers || (!allFilled && !allEmpty)) {
      return
    }

    const serviceList = [
      'sportsEquipment',
      'checkedBaggage',
      'medicalAssistance',
      'pets',
      'unaccompaniedMinor',
      'heavyBaggageFee',
      'oversizedBaggageCharge',
      'mealRequest',
      'seatRequest',
      'airportCheckinAndBag',
      'fastTrack',
      'priorityBoarding',
      'sustainableAviationFuel'
    ]

    if (serviceList.includes(name)) {
      // only if empty
      if (allEmpty && !allFilled) {
        this.paxAllEmpty(name, data, service)
      } else {
        return
      }
    } else {
      if (allEmpty && !allFilled) {
        this.paxAllEmpty(name, data, service)
      }

      if (allFilled && !allEmpty) {
        this.paxAllSet(name, data, service, excludeText)
      }
    }

    service.passengers = [data]
  }

  paxAllSet(name, data, service, excludeText = false) {
    service.all = true
    service.empty = false

    if (!excludeText) {
      data.text = this.added
    }
  }

  paxAllEmpty(name, data, service) {
    data.text = this.notAdded
    service.all = true
    service.empty = true

    if (name === 'checkinStatuses') {
      data.text = this.translateKey('reusable.notCheckedIn')
    }
  }

  addDataToPax(name, passenger, service, isOffers = false, nameChanged = false) {
    let serviceData = {}

    if (name === 'cabinBaggage') {
      serviceData = this.getCabinBaggageData(service)
    } else if (name === 'checkedBaggage') {
      const getCheckedBaggageData = this.getCheckedBaggageData(service)

      if (getCheckedBaggageData.count !== 0) {
        serviceData = getCheckedBaggageData
      }
    } else {
      serviceData = this.getServiceData(service, name, nameChanged)
    }

    if (Object.keys(serviceData).length > 0 && !isOffers) {
      if (['seatRequest'].includes(name)) {
        if (!isUndefined(passenger.items[0])) {
          const firstItem = head(passenger.items)
          firstItem.dcsSeatNumber = serviceData.dcsSeatNumber

          if (firstItem.dcsSeatNumber !== firstItem.seatNumber) {
            firstItem.text = this.translateKey('selfService.dcsSeatAdded', {
              oldSeat: firstItem.seatNumber,
              newSeat: firstItem.dcsSeatNumber
            })
          }
        }

        passenger.items = [serviceData]
      } else if (name === 'sustainableAviationFuel') {
        if (
          ['10PERCENT', '100PERCENT', '50PERCENT', '2PERCENT'].includes(serviceData.type)
        ) {
          const percentText = serviceData.type.replace('PERCENT', '') + '%'
          serviceData.text += ' - ' + percentText
          passenger.items.push(serviceData)
        }
      } else {
        passenger.items.push(serviceData)
      }
    }

    if (name === 'mealRequest') {
      const isBusinessSegment = this.isBusinessSegment(service.segmentId)

      if (isBusinessSegment) {
        if (!isUndefined(passenger.items[1])) {
          passenger.items[1] = [serviceData]
        } else {
          passenger.items.push(serviceData)
        }
      }
    }

    const action = get(service, 'action', null)

    if (action) {
      passenger.button = {
        action,
        link: get(service, 'link', null)
      }
    }

    const itemType = get(service, 'baggageItem', null)

    if (itemType !== null) {
      const text = this.translateKey(
        'selfService.bagType',
        { count: 1, type: this.getType(itemType) },
        1
      )
      passenger.items.push({
        count: 1,
        status: 'confirmed',
        type: itemType,
        text
      })
    }

    return passenger
  }

  createPaxInNotExists(servicePassengers, passengerId, segmentName, segmentId) {
    const foundPax = this.findPassenger(passengerId)
    const pax = find(
      servicePassengers,
      (servicePassenger) => servicePassenger.id === passengerId
    )

    if (isUndefined(pax)) {
      servicePassengers.push(this.setDefaultPassenger(foundPax, segmentName, segmentId))
    }

    return servicePassengers
  }

  findPassenger(passengerId) {
    return find(passengers, (passenger) => passenger.id === passengerId)
  }

  getHeaderData(service, segmentId, nameChanged) {
    const data = Object.keys(this.servicesList).includes(service)
      ? cloneDeep(this.servicesList[`${service}`])
      : {}

    const isBusinessSegment = this.isBusinessSegment(segmentId)
    const isBTOperatedSegment = this.isBTOperatedSegment(segmentId)

    if (isBusinessSegment && isBTOperatedSegment && service === 'mealRequest') {
      data.description = this.translateKey(
        'selfService.services.meal.businessDescription'
      )
    }

    if (['cabinBaggage', 'checkedBaggage'].includes(service) && !isBTOperatedSegment) {
      data.description = ''
    }

    if (['seatRequest'].includes(service)) {
      if (nameChanged) {
        data.description = this.translateKey(
          'selfService.services.seat.extrasDescription'
        )
      }

      if (isBusinessSegment) {
        data.description = ''
      }
    }

    data.timeAtGate = ''

    if (service === 'checkinStatuses') {
      const segment = this.getSegment(segmentId)
      data.timeAtGate = get(segment, 'beAtGateTime', '')
    }

    return data
  }

  getCabinBaggageData(service) {
    const count = get(service, 'baggageAllowance', 1)
    const weight = get(service, 'unitWeight', 8)
    const unit = this.getUnit(service)

    let text = this.translateKey(
      'selfService.bagWeight',
      { count, weight, unit: this.getUnitTranslation(unit) },
      count
    )
    const status = 'confirmed'

    const isBusinessSegment = this.isBusinessSegment(service.segmentId)
    const isBTOperatedSegment = this.isBTOperatedSegment(service.segmentId)
    const isVipPassenger = this.isVipPassenger(service.passengerId)
    const isACMISegment = this.isACMISegment(service.segmentId)

    if ((isBusinessSegment && isBTOperatedSegment) || isVipPassenger) {
      text = this.translateKey('selfService.businessBag', { count }, count)
    }

    if (!isBTOperatedSegment) {
      text = this.translateKey('selfService.otherCarrierBag', { count }, count)
    }

    if (isACMISegment) {
      text = this.translateKey(
        'selfService.acmiBagWeight',
        { count, weight, unit: this.getUnitTranslation(unit) },
        count
      )
    }

    return {
      count,
      weight,
      unit,
      text,
      status
    }
  }

  translateKey(key, variables = {}, count = 1) {
    return Vue.i18n.translate(key, variables, count)
  }

  isVipPassenger(passengerId) {
    const pax = this.findPassenger(passengerId)

    if (pax) {
      return pax.abClubLevel === 'VIP'
    }

    return false
  }
  isBusinessSegment(segmentId) {
    const segment = this.getSegment(segmentId)

    if (segment) {
      return segment.serviceClass === BUSINESS
    }

    return false
  }

  isBTOperatedSegment(segmentId) {
    const segment = this.getSegment(segmentId)

    if (segment) {
      return segment.operatedBy === OPERATED_BY_BT
    }

    return false
  }

  isACMISegment(segmentId) {
    const segment = this.getSegment(segmentId)

    if (segment) {
      return segment.isACMI
    }

    return false
  }

  getSegment(segmentId) {
    return find(flights, (flight) => flight.id === segmentId)
  }

  getCheckedBaggageData(service) {
    const count = this.getCount(service)
    const unit = this.getUnit(service)
    const weight = this.getWeight(service, unit)
    let text = ''
    const status = 'confirmed'
    const baggageType = get(service, 'quantityCode', null)
    const baggageItem = get(service, 'baggageItem', null)

    const isBTOperatedSegment = this.isBTOperatedSegment(service.segmentId)

    if (!isBTOperatedSegment) {
      text = this.translateKey('selfService.bagCount', { count }, count)
    }

    if (baggageType === UNIT_WEIGHT && isNull(baggageItem)) {
      let limit = BAG_WEIGHT_LIMIT_KG

      if (unit === UNIT_POUNDS) {
        limit = BAG_WEIGHT_LIMIT_POUNDS
      }

      if (weight > limit) {
        text = this.translateKey('selfService.bagWeightMoreThanLimit', {
          weight,
          unit: this.getUnitTranslation(unit)
        })
      } else {
        text = this.translateKey('selfService.bagWeightLessThanLimit', {
          weight,
          unit: this.getUnitTranslation(unit)
        })
      }
    } else {
      text = this.translateKey(
        'selfService.checkedBagWeight',
        { count, weight, unit: this.getUnitTranslation(unit) },
        count
      )
    }

    return {
      count,
      weight,
      unit,
      text,
      status
    }
  }

  getNotifications(name, service, dcsSeats = false) {
    const status = get(service, 'status', '')

    if (name === 'seatRequest' && dcsSeats) {
      return { type: 'dcsSeats' }
    }

    if (['waitlist', 'unconfirmed'].includes(status)) {
      return { type: status }
    }
  }

  getServiceData(service, name, nameChanged) {
    const data = {}
    let count = 0
    let status = null
    let type = null
    let serviceName = null

    const isBusinessSegment = this.isBusinessSegment(service.segmentId)
    const isBTOperatedSegment = this.isBTOperatedSegment(service.segmentId)

    if (has(service, 'quantity')) {
      data.count = service.quantity
      count = service.quantity
    }

    let text = ''

    if (count > 0) {
      text = this.added
    }

    if (has(service, 'status')) {
      data.status = service.status
      status = service.status
    }

    if (status === 'unconfirmed') {
      text = this.translateKey(`selfService.unconfirmed`)
    }

    if (status === 'waitlist') {
      text = this.translateKey(`selfService.waitlist`)
    }

    if (has(service, 'type')) {
      data.type = service.type
      type = service.type
    }

    if (has(service, 'service')) {
      serviceName = service.service
    }

    if (has(service, 'seatNumber')) {
      data.seatNumber = service.seatNumber
      text = this.translateKey('selfService.seatAdded', { seat: service.seatNumber })
    }

    if (has(service, 'dcsSeatNumber')) {
      data.dcsSeatNumber = service.dcsSeatNumber
      data.status = 'confirmed'
      text = this.translateKey('selfService.seatAdded', { seat: service.dcsSeatNumber })
    }

    if (name === 'mealRequest' && type !== 'SPML') {
      if (count === 1) {
        text = this.translateKey(this.getMealType(type))
        data.status = status
      } else if (count > 1) {
        text = this.translateKey(
          'selfService.mealType',
          { count, type: this.getMealType(type) },
          count
        )
        data.status = status
      } else {
        text = this.notAdded
        data.status = 'empty'
      }

      if (isBTOperatedSegment && isBusinessSegment && count === 0) {
        text = this.translateKey('selfService.businessMeal')
        data.status = 'meal'
      }

      if (type?.substring(0, 4) === 'MEAL') {
        text = this.translateKey(
          'selfService.mealType',
          { count, type: this.getMealType('SPML') },
          count
        )
      }
    }

    if (name === 'checkinStatuses') {
      if (status === null) {
        data.status = 'not-checked-in'
        data.value = ''
        text = this.translateKey('reusable.notCheckedIn')
      } else {
        let currentStatus = null
        if (Array.isArray(status)) {
          currentStatus = head(status)
        } else if (typeof status === 'string') {
          currentStatus = status
        }

        const hasStatus = ['CAC', 'CST'].includes(currentStatus)

        data.value = currentStatus
        data.status = hasStatus ? 'confirmed' : 'not-checked-in'
        text = hasStatus
          ? this.translateKey('reusable.checkedIn')
          : this.translateKey('reusable.notCheckedIn')

        if (['CRJ'].includes(currentStatus)) {
          text = this.translateKey('reusable.checkInRejected')
        }
      }
    }

    if (name === 'sportsEquipment' && !isNull(type)) {
      text =
        count > 0
          ? this.translateKey(
              'selfService.bagType',
              { count, type: this.getSpeqType(type) },
              count
            )
          : this.notAdded
    }

    if (name === 'medicalAssistance') {
      if (serviceName !== 'wheelchair') {
        text = this.translateKey(
          'selfService.assistanceType',
          {
            count,
            type: this.translateKey(`selfService.assistanceItems.${serviceName}`)
          },
          count
        )
      } else {
        text = this.translateKey('selfService.assistanceType', {
          type: this.translateKey(`selfService.assistance.wheelchair.${type}`)
        })
      }
    }

    if (['extraSeatForBaggage', 'extraSeatForComfort'].includes(name) && nameChanged) {
      text = this.translateKey(`selfService.seatItems.${serviceName}.${status}`, {
        seat: get(service, 'seatNumber', '')
      })
    }

    if (name === 'fragileItem') {
      text = this.translateKey('selfService.extraCabinBag', { count }, count)
    }

    if (name === 'oversizedBaggageCharge') {
      text = this.translateKey('selfService.oversizedBaggageCharge', { count }, count)
    }

    if (name === 'heavyBaggageFee') {
      text = this.translateKey('selfService.heavyBaggageFee', { count }, count)
    }

    if (name === 'unaccompaniedMinor') {
      text = this.translateKey(`selfService.umnrMessage.${status}`)
    }

    if (name === 'pets') {
      text = this.translateKey(
        'selfService.petsType',
        {
          count,
          type: this.translateKey(`selfService.petsItems.${serviceName}`, {}, count)
        },
        count
      )
    }

    if (serviceName === 'prioCheckin') {
      text = this.translateKey('selfService.services.airportCheckinAndBag.prioCheckin')
    }

    if (serviceName === 'checkinFee') {
      text = this.translateKey('selfService.services.airportCheckinAndBag.checkinFee')
    }

    if (text !== '') {
      data.text = text
    }

    return data
  }

  parseFlight(segment, key, directions, firstSegment, updated = false) {
    const arrival = moment(segment.arrival)
    const departure = moment(segment.depart)

    const mins = segment.flightDurationInMinutes
    const gap = get(segment, 'nextFlightGapInMinutes', 0)

    const interline = find(directions, (item) => item.segments?.includes(segment.id))

    const status = segment.flown ? 'flown' : 'scheduled'
    const nextDay = moment
      .parseZone(segment.arrival)
      .startOf('day')
      .diff(moment.parseZone(segment.depart).startOf('day'), 'days')

    const flightNotFlown = status !== FLOWN
    openBlocks.push(flightNotFlown)
    const activeFlightCount = openBlocks.filter((value) => value === true).length

    let openBlock = false

    if (activeFlightCount <= 1 && flightNotFlown) {
      openBlock = true
    }
    const beAtGate = get(segment, 'beAtGate', null)
    let beAtGateTime = ''
    if (!isNull(beAtGate)) {
      beAtGateTime = moment(beAtGate).format('HH:mm')
    }

    let flightOrigin = this.getFlightOrigin(segment)
    let flightDestin = this.getFlightDestin(segment)

    const data = {
      firstSegment,
      segmentId: segment.id,
      arrival: arrival.toISOString(),
      departure: departure.toISOString(),
      durationMinutes: mins,
      duration: this.getTimeFromMins(mins, false),
      durationWithZeroes: this.getTimeFromMins(mins, false, true),
      nextDay: nextDay > 0,
      gapMinutes: gap,
      gap: this.getTimeFromMins(gap, false),
      bookingClass: segment.bookingClass,
      carrier: segment.carrier,
      carrierName: segment.carrierDescription,
      number: segment.flightNumber,
      arrivalTerminal: segment.arrivalTerminal,
      departureTerminal: segment.departureTerminal,
      flightNumber: segment.carrier + segment.flightNumber,
      aircraftType: get(segment, 'aircraftType', ''),
      aircraft: get(segment, 'aircraftType', ''),
      aircraftName: get(segment, 'aircraftTypeDescription', ''),
      acmiOperatedByDescription: get(segment, 'acmiOperatedByDescription', null),
      acmiAircraftTypeDescription: get(segment, 'acmiAircraftTypeDescription', null),
      id: segment.id,
      orig: segment.orig,
      dest: segment.dest,
      origDescription: get(segment, 'origDescription', ''),
      destDescription: get(segment, 'destDescription', ''),
      airportDiffers: segment.departureAirportDiffersArrivalAirport,
      operatedBy: get(segment, 'operatedBy'),
      isACMI: !!get(segment, 'acmiOperatedBy'),
      operatedByName: get(segment, 'operatedByDescription', ''),
      operatedByDescription: get(segment, 'operatedByDescription', ''),
      serviceClass: segment.serviceLevel,
      beAtGateTime,
      open: openBlock,
      status,
      tab: 'services',
      segmentStatus: get(segment, 'status'),
      segmentTitle: `${flightOrigin.city} (${flightOrigin.code}) - ${flightDestin.city} (${flightDestin.code})`,
      origCity: flightOrigin.city,
      origCountry: flightOrigin.country ?? '',
      destCity: flightDestin.city,
      destCountry: flightDestin.country ?? '',
      origApt: flightOrigin.apt,
      destinApt: flightDestin.apt,
      updated,
      automaticFlightOffer: segment?.automaticFlightOffer,
      timeChangeMinutes: segment.timeChangeMinutes ?? 0,
      arrivalTimeChangeMinutes: segment.arrivalTimeChangeMinutes ?? 0,
      isPrebooked: segment.isPrebooked
    }

    if (data.isACMI) {
      data.operatedBy = get(segment, 'acmiOperatedBy', '')
      data.operatedByName =
        get(segment, 'acmiOperatedByDescription') || get(segment, 'acmiOperatedBy', '')
      data.aircraftName = get(segment, 'acmiAircraftTypeDescription', '')
      data.aircraftType = get(segment, 'acmiAircraftType', '')
      data.aircraft = get(segment, 'acmiAircraftType', '')
    }

    if (!isUndefined(interline)) {
      if (
        interline.transitFlightWithLostConnection &&
        segment.id === interline.lostConnectionSegmentId
      ) {
        data.isLostConnection = true
      }

      data.isInterline = get(interline, 'interlineOrCodeshare', false)
    }

    return data
  }

  getFlightOrigin(flight) {
    const orig = this.origDestData[`${flight.orig}A`]

    if (!orig) {
      return {
        city: flight.origDescription,
        code: flight.orig
      }
    }

    return orig
  }

  getFlightDestin(flight) {
    const dest = this.origDestData[`${flight.dest}A`]

    if (!dest) {
      return {
        city: flight.destDescription,
        code: flight.dest
      }
    }

    return dest
  }

  getTimeFromMins(mins, shortFormat = true, showZeroHour = false) {
    const h = Math.floor(mins / 60)
    const m = mins % 60
    const mC = m < 10 ? '0' + m : m

    if (shortFormat) {
      return `${h}:${mC}`
    } else {
      if (h === 0 && !showZeroHour) {
        return `${mC}min`
      }

      return `${h}h ${mC}min`
    }
  }

  getAlertsFromPimcore(postData) {
    return new Pimcore()
      .post(`/self-service-alerts/`, JSON.stringify(postData))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error))
  }

  saveIrregularityContacts(postData) {
    return new Api()
      .post(`/mmb/pnr/retrieve/irregularity/contact/update`, postData)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }

  getInsuranceTypeKey(insuranceType) {
    if (!insuranceType) {
      return ''
    }

    return insuranceTypeMap[insuranceType.toUpperCase()]
  }

  getAltFlights(postData) {
    return new Api()
      .post(`/mmb/pnr/retrieve/alternativeFlights`, postData)
      .then((response) => {
        let journeys = response.data.journeys
        if (this.origDestData) {
          journeys.forEach((journey) => {
            journey.flights.forEach((flight) => {
              const orig = this.origDestData[`${flight.departureAirport}A`]
              const dest = this.origDestData[`${flight.arrivalAirport}A`]

              if (orig) {
                flight.orig = flight.departureAirport
                flight.origCity = orig.city
                flight.origCountry = orig.country
                flight.origApt = orig.apt
              }

              if (dest) {
                flight.dest = flight.arrivalAirport
                flight.destCity = dest.city
                flight.destCountry = dest.country
                flight.destinApt = dest.apt
              }

              flight.flightNumber = flight.marketingCompany + flight.flightNumber
              flight.operatedByName = flight.operatingCompanyDescription
              flight.aircraftName = flight.aircraftTypeDescription
              flight.durationWithZeroes = this.getTimeFromMins(
                flight.flightDurationInMinutes,
                false,
                true
              )
            })
          })
        }

        return Promise.resolve(journeys)
      })
      .catch((error) => Promise.reject(error))
  }

  getServiceClass(fareTypeDescription) {
    if (fareTypeDescription === 'ECONOMY_CLASSIC') {
      return 'classic'
    } else if (fareTypeDescription === 'ECONOMY_BASIC') {
      return 'basic'
    }
  }

  getVolDateChangeFlightList(postData) {
    return new Api()
      .post('/voluntary-date-change/search', postData)
      .then((response) => {
        let directions = []
        response.data?.dateChanges?.forEach((dateChange) => {
          let direction = dateChange.directions.find(
            (dir) => dir.index === postData.directionIndex
          )
          if (!direction) return
          let serviceClass = direction.fareTypeDescription
          direction.flights = direction?.flights.map((flight) =>
            this.formatFlightForVolDateChange(flight, serviceClass)
          )
          direction.directionFlightsTokens = direction.flightsToken
          direction.ticketDetails = dateChange.ticketDetails
          direction.serviceClass = serviceClass
          directions.push(direction)
        })
        let warnings = null

        if (response.status === 208) {
          warnings = this.translateKey('volDateChange.errors.dateChangePending')
        }
        return Promise.resolve({ directions, warnings })
      })
      .catch((error) => {
        const errorKey = ErrorHandler.getErrorTranslationKey(
          VolDateChangeModel.validationErrors,
          error.response
        )
        return Promise.reject({ message: errorKey })
      })
  }

  formatFlightForVolDateChange(flight, serviceClass) {
    flight.orig = flight.origin
    flight.dest = flight.destination
    let flightOrigin = this.getFlightOrigin(flight)
    let flightDestin = this.getFlightDestin(flight)
    const arrival = moment(flight.arrDateTime)
    const departure = moment(flight.depDateTime)
    const mins = flight.flightDurationInMinutes
    const gap = get(flight, 'nextFlightGapInMinutes', 0)
    const nextDay = moment
      .parseZone(flight.arrDateTime)
      .startOf('day')
      .diff(moment.parseZone(flight.depDateTime).startOf('day'), 'days')
    let flightNumber = flight.flightNumber
    if (flightNumber.charAt(0) === '0') {
      flightNumber = flightNumber.substring(1)
    }

    return {
      ...flight,
      arrival: arrival.toISOString(),
      departure: departure.toISOString(),
      aircraftName: get(flight, 'equipmentDescription', ''),
      carrierName: get(flight, 'marketingCarrierDescription', ''),
      durationMinutes: mins,
      duration: this.getTimeFromMins(mins, false),
      durationWithZeroes: this.getTimeFromMins(mins, false, true),
      nextDay: nextDay > 0,
      gapMinutes: gap,
      operatedBy: flight.operatingCarrier,
      serviceClass,
      flightNumber: flight.marketingCarrier + flightNumber,
      gap: this.getTimeFromMins(gap, false),
      origin: flightOrigin.code,
      destin: flightDestin.code,
      origCity: flightOrigin.city,
      origCountry: flightOrigin.country ?? '',
      destCity: flightDestin.city,
      destCountry: flightDestin.country ?? '',
      origDescription: flightOrigin.apt,
      destDescription: flightDestin.apt,
      origApt: flightOrigin.apt,
      destinApt: flightDestin.apt
    }
  }

  getVolDateChangeSummary(postData) {
    return new Api()
      .post('/voluntary-date-change/summary', postData)
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        const errorKey = ErrorHandler.getErrorTranslationKey(
          VolDateChangeModel.validationErrors,
          error.response
        )
        return Promise.reject({ message: this.translateKey(errorKey) })
      })
  }

  submitVolDateChange(postData) {
    return new Api()
      .post('/voluntary-date-change/submit', postData)
      .then((response) => {
        if (response.status === 208) {
          return Promise.reject({
            message: this.translateKey('volDateChange.errors.dateChangePending')
          })
        }
        return Promise.resolve(response)
      })
      .catch((error) => {
        const errorKey = ErrorHandler.getErrorTranslationKey(
          VolDateChangeModel.validationErrors,
          error.response
        )
        return Promise.reject({ message: this.translateKey(errorKey) })
      })
  }
}

export default SelfService
