var SelfService = /** @class */ (function () {
    function SelfService() {
        this.pnr = '';
        this.surname = '';
        this.retrieveInIbe = false;
        this.corporate = false;
        this.seamen = false;
        this.totalDuration = 0;
        this.isIdTravel = false;
        this.sixtAdded = false;
        this.sustainableAviationFuel = false;
        this.voluntaryDateChangePossible = false;
        this.services = {
            checkinStatuses: {
                name: 'selfService.services.checkinStatuses.title',
                description: "selfService.services.checkinStatuses.description"
            },
            cabinBaggage: {
                name: 'selfService.services.cabinBaggage.title',
                description: 'selfService.services.cabinBaggage.description'
            },
            checkedBaggage: {
                name: 'selfService.services.checkedBaggage.title',
                description: 'selfService.services.checkedBaggage.description'
            },
            sportsEquipment: {
                name: 'selfService.services.sportsEquipment.title'
            },
            heavyBaggageFee: {
                name: 'selfService.services.heavyBaggageFee.title'
            },
            seatRequest: {
                name: 'selfService.services.seat.title'
            },
            earlierOnlineCheckIn: {
                name: 'selfService.services.earlierOnlineCheckIn.title'
            },
            mealRequest: {
                name: 'selfService.services.meal.title',
                description: 'selfService.services.meal.description'
            },
            prioCheckin: {
                name: 'selfService.services.priorityCheckin.title'
            },
            airportCheckinAndBag: {
                name: 'selfService.services.airportCheckinAndBag.title'
            },
            fastTrack: {
                name: 'selfService.services.fastTrack.title'
            },
            cancellationWarranty: {
                name: 'selfService.services.cancellationWarranty.title'
            },
            sustainableAviationFuel: {
                name: 'selfService.services.saf.title'
            },
            delayedBaggageWarranty: {
                name: 'selfService.services.delayedBagWaranty.title'
            },
            paxUpgrade: {
                name: 'selfService.services.upgradeToBusiness.title'
            },
            medicalAssistance: {
                name: 'selfService.services.medicalAssistance.title'
            },
            unaccompaniedMinor: {
                name: 'selfService.services.unaccompaniedMinor.title'
            },
            pets: {
                name: 'selfService.services.pets.title'
            },
            checkinFee: {
                name: 'selfService.services.checkinFee.title',
                description: 'selfService.services.checkinFee.description'
            },
            priorityBoarding: {
                name: 'selfService.services.priorityBoarding.title'
            }
        };
    }
    return SelfService;
}());
export default SelfService;
