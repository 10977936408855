<template>
  <footer>
    <template v-if="show">
      <div class="group">
        <footer-links
          v-if="storyblokFooterLinks.items"
          :footer-links="storyblokFooterLinks.items"
        />
        <footer-payments :payment-logos="paymentLogos" />
        <footer-legal :items="storyblokFooterLinks.legalLinks" />
        <footer-disclaimers />
      </div>

      <div class="last">
        <div
          v-if="awards.length"
          class="container award"
        >
          <div
            v-for="(award, i) in awards"
            :key="'award-' + i"
            class="award"
          >
            <img
              :src="award.src"
              :alt="award.alt"
              class="award-image"
            />
            <span class="award-title">{{ award.title }}</span>
          </div>
        </div>
        <div class="container">
          <a
            :href="copyright.href"
            :target="copyright.target"
            class="copyright"
          >
            {{ copyright.text }}
          </a>
          <div class="left">
            <a
              :href="storyblokFooterLinks.contactUs.href"
              :target="storyblokFooterLinks.contactUs.target"
              class="contact-us"
            >
              {{ storyblokFooterLinks.contactUs.text }}
            </a>
            <footer-social :social="social" />
          </div>
        </div>
      </div>
    </template>

    <template v-if="show">
      <json-ld
        v-if="jsonld"
        :jsonld="jsonld"
      />
      <subscribe-popup
        v-if="showSubscribePopup"
        :closed-popup-cookie-name="closedPopupCookie"
        :subscribe-source="'index-popup'"
        :subscribed-popup-cookie-name="subscribedPopupCookie"
        :viewed-popup-cookie-name="viewedPopupCookie"
        @subscribed="subscribeCallback"
      />
    </template>
  </footer>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import FooterSocial from 'components/Storyblok/Footer/FooterFacelift/Social.vue'
  import FooterLegal from 'components/Storyblok/Footer/FooterFacelift/Legal.vue'
  import FooterPayments from 'components/Storyblok/Footer/FooterFacelift/Payments.vue'
  import FooterDisclaimers from 'components/Storyblok/Footer/FooterFacelift/Disclaimers.vue'
  import FooterLinks from 'components/Storyblok/Footer/FooterFacelift/Links.vue'
  import CookieUtils from 'utils/CookieUtils'

  const JsonLd = () => import(/* webpackChunkName: "json-ld" */ 'containers/JsonLD.vue')

  const SubscribePopup = () =>
    import(
      /* webpackChunkName: "subscribe-popup" */ 'containers/Pages/IndexPage/SubscribePopup'
    )

  export default {
    name: 'FooterWide',
    components: {
      FooterSocial,
      FooterLegal,
      FooterPayments,
      FooterDisclaimers,
      FooterLinks,
      JsonLd,
      SubscribePopup
    },
    data() {
      return {
        subscribedPopupCookie: 'subscribePopupHide',
        closedPopupCookie: 'subscribePopupClosed',
        viewedPopupCookie: 'subscribePopupViewed',
        hasSubscribed: false,
        show: true
      }
    },
    computed: {
      ...mapState('footer', [
        'storyblokFooterLinks',
        'subscribePopupDisabled',
        'footerSemantics',
        'awards',
        'copyright',
        'social',
        'paymentLogos'
      ]),
      ...mapGetters('device', ['isMobile', 'isDesktop']),
      ...mapState('main', ['hideCookieAlert', 'pageType', 'selectedLang']),
      ...mapState('search', ['searchPageUrl']),
      isNewsletterPage() {
        return this.pageType === 'newsletter'
      },
      showSubscribePopup() {
        const cookieValue = parseInt(CookieUtils.getCookie(this.subscribedPopupCookie))
        return (
          !this.subscribePopupDisabled &&
          !this.isNewsletterPage &&
          !cookieValue &&
          this.isDesktop &&
          !this.hasSubscribed
        )
      },
      jsonld() {
        const sameAs = this.social.map((item) => item.link)

        if (Object.keys(this.footerSemantics).length === 0) {
          return null
        }

        return {
          '@context': 'http://schema.org',
          '@graph': [
            {
              '@context': 'http://schema.org',
              '@type': 'WebSite',
              url: window.location.origin,
              potentialAction: {
                '@type': 'SearchAction',
                target:
                  window.location.origin +
                  this.footerSemantics.searchPageUrl +
                  '?search={search_term_string}',
                'query-input': 'required name=search_term_string'
              }
            },
            {
              '@context': 'http://schema.org',
              '@type': 'Organization',
              url: window.location.origin,
              logo: this.footerSemantics.logo,
              name: 'AirBaltic',
              email: this.footerSemantics.email,
              address: {
                '@type': 'PostalAddress',
                streetAddress: this.footerSemantics.address,
                addressCountry: 'LV'
              },
              contactPoint: [
                {
                  '@type': 'ContactPoint',
                  telephone: this.footerSemantics.phone,
                  contactType: 'customer service'
                }
              ],
              sameAs
            }
          ]
        }
      }
    },
    created() {
      if (this.isMobile) {
        const param = CookieUtils.getCookie('btMobileApp')

        this.show = param === ''
      }
    },
    methods: {
      subscribeCallback() {
        this.hasSubscribed = true
      }
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  footer {
    background: $blue-bg-css;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .group {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 0 16px;

    @include bp-mf-desktop {
      padding: 40px 0 8px;
      margin: 0 auto;
      gap: 40px;
      max-width: $desktop-lg;
    }
  }

  .last {
    background: $blue-dark;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 32px 16px;

      @include bp-mf-desktop {
        flex-direction: row;
        max-width: $desktop-lg;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0 auto;
      }

      &.award {
        align-items: start;
        justify-content: space-between;
        gap: 32px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        padding: 32px 40px;

        @include bp-mf-tablet {
          flex-direction: row;
          align-items: center;
          gap: 40px;
          padding: 32px 20px;
        }

        .award {
          display: flex;
          flex-direction: row;
          max-width: 256px;
          align-items: center;
          gap: 32px;

          &-image {
            width: 64px;

            @include bp-mf-desktop-large {
              width: 88px;
            }
          }

          &-title {
            color: white;
            text-align: left;
          }
        }
      }

      .left {
        display: flex;
        flex-direction: column;
        gap: 32px;
        text-align: center;

        @include bp-mf-desktop {
          flex-direction: row;
          align-items: center;
          gap: 24px;
        }
      }
    }
  }

  .contact-us {
    color: white;
    font-size: rem(16, 16);
    line-height: rem(24, 16);
    text-decoration: underline;
    text-transform: uppercase;
    order: 1;

    &:focus {
      text-decoration: none;
      color: $blue-dark-2;
    }

    @include bp-mf-desktop {
      font-size: rem(14, 16);
      line-height: rem(14, 16);
      text-decoration: none;
      order: initial;
    }
  }

  ::v-deep .payment-logos {
    @include bp-mobile {
      order: 2;
    }
  }

  .copyright {
    color: $scn-gray-border;
    font-size: rem(14, 16);
    line-height: rem(20, 16);
    text-decoration: none;

    order: 2;

    @include bp-mf-desktop {
      order: initial;
    }
  }

  .footer-links {
    order: 0;

    @include bp-mf-desktop {
      order: initial;
    }
  }

  .footer-legal {
    order: 1;

    @include bp-mf-desktop {
      order: initial;
    }
  }

  .payment-options {
    order: 2;

    @include bp-mf-desktop {
      order: initial;
    }
  }

  .disclaimers {
    order: 3;

    @include bp-mf-desktop {
      order: initial;
    }
  }
</style>
