module.exports = {
    btUrl: "/",
    btTranslationsUrl: 'https://www.airbaltic.com/',
    btApiUrl: 'https://api.airbaltic.com',
    btApiLiveUrl: 'https://api.airbaltic.com',
    translateCors: '',
    btUrlCors: "",
    translationPath: '../../website/static/generatedData/vue/translations',
    fullTranslationPath: 'website/static/generatedData/vue/translations',
    jwtCookieName: 'bt_auth_jwt',
    jwtLoginTokenType: 'DEFAULT',
    eeUrl: 'https://book.airbaltic.com',
    newEeUrl: 'https://book.airbaltic.com',
    gygPartnerId: '0CUHY2M',
    gygWidgetUrl: 'https://widget.getyourguide.com',
    fsfCookieName: 'e-horizon-fsf',
    newIbeUrl: 'https://fly.airbaltic.com',
    oldIbeUrl: 'https://book.airbaltic.com',
    fsfSwitcherCookieName: 'e-horizon-fsf-switcher-live-11',
    eHorizonSwitcherEnabledForEverybody: false,
    eHorizonVersionEnabled: false,
    eHorizonVersionA: 0.7,
    eHorizonVersionB: 0.3,
    newCLPEnabled: true,
    reCaptchaEnabled: false,
    reCaptchaSiteKey: '',
    cssReCaptchaSiteKey: '6LcOdtgUAAAAAHjZnKjOywShB7dSzdmDe238DOHy',
    fbChatBotId: '2024869001060759',
    fbChatbotPageId: '101938340015',
    env: 'prod',
    gcsApiUrl: 'https://www.googleapis.com/customsearch/v1/siterestrict',
    gcsApiKey: 'AIzaSyCxxHts-M9aJdioOLABQjsJ8dWJZ3X6iVk',
    gcsEngineKey: '013977052835775744593:djp6bbs5bli',
    divolte: true,
    sentryBeta: true,
    sentrySampleRate: 0.005,
    sentryReplaysOnErrorSampleRate: 0.1,
    sentryReplaysSessionSampleRate: 0.1,
    selfServiceEnabled: true,
    newCKIEnabled: false,
    eveApi: 'https://payments.airbaltic.com',
    mapsApiKey: 'AIzaSyAalRDsP2jOEFUQjDDfocrnI99X41jtVCI',
    sherpaId: 'airbaltic',
    sherpaKey: 'AIzaSyADYOgwofjpMeGo0RG5yUTnNReX9JWUGOg',
    sherpaAppId: 'tkM2MjMyOT',
    bookingAidFSF: '381407',
    bookingLabelFSF: 'search_extension-link-381407-click',
    tripX: 'https://airbalticholidays.com/eu/',
    appStoreUrl: 'https://apps.apple.com/app/pins-mobile-app/id1144087109',
    googlePlayStoreUrl: 'https://play.google.com/store/apps/details?id=com.redmadrobot.pins&hl=en',
    fb_access_token: 'EAAn8wUceq8MBAMRetMl8VZA8vQ5frtrj6bqFnXHKVZBHFyu47fV0ScO1mzE6bxBKio8p9mjkTUCfVJzDj30MCyl2ZCrFzpR62bxD5gMZCPkxQL9GQJirslpMpctMK8silarH0Nu9EiMVZALoZBsOKhoZAvUbOUIkRn1dJMRFQDZA6VJEdbZAZCT05pKPtcTWtqalkZD',
     fb_pixel_id: 598326910344275,
    tripXApi: 'https://wp.tripx.eu/wp-json/tripx/v1',
    tripXApiCalendar: 'https://bkkbbi8sf9.execute-api.eu-west-1.amazonaws.com/prod/p?use-staging-api=',
    tripXSearch: 'https://airbalticholidays.com',
    abCookieTest: 'a-b-cookie-test',
    abOverlayTest: 'ovl-a-b-test',
    sixtAppId: 102.14,
    sixtAppUrlSearch: 'https://search-ux.meili.travel/index.js',
    sixtAppUrlDirect: 'https://direct-ux.meili.travel/index.js',
   storyblokToken: 'XuSPdUwczOJQ0IAZ5X7MGwtt',
   storyblokVersion: 'published',
   storyblokEnabled: true,
    storyblokProxy: 'https://storyblok.airbaltic.com/v2',
    plusgradePid: '8kPluu2f92BT',
    plusgradeApiKey: 'S8ziHpYOgBToNDzLvcnwEwve',
    vertexProjectID: '548621600352',
    vertexAppID: 'airbaltic-search_1730723533423',
    vertexApiKey: 'AIzaSyCxxHts-M9aJdioOLABQjsJ8dWJZ3X6iVk',
    assetsVersion: 'mdf88fgwertata'
}