<template>
  <div class="footer-legal">
    <ul class="legal-items">
      <li
        v-for="item in items"
        :key="item._uid"
      >
        <a
          :href="item.href"
          :title="item.text"
        >
          {{ item.text }}
        </a>
      </li>

      <li>
        <button @click="changePreferences">
          {{ $t(`footerWide.bottom.cookieList.text`) }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'FooterLegal',
    props: {
      items: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      ...mapState({
        cookieTypes: (state) => state.main.gdprCookie,
        selectedLang: (state) => state.main.selectedLang
      })
    },
    methods: {
      changePreferences() {
        Cookiebot.show()
      }
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .legal-items {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-wrap: wrap;

    a,
    button {
      color: $blue-dark;
      font-size: rem(14, 16);
      line-height: rem(20, 16);
      text-decoration: none;
      font-weight: 400;

      &:focus {
        text-decoration: underline;
      }
    }

    @include bp-mf-tablet {
      flex-direction: row;
      gap: 16px 32px;
    }
  }
</style>
