import SignUp from 'services/AirBaltic/Pax/SignUp'
import Form from 'models/Forms/Profile/Profile'
import FormValidationUtils from 'utils/FormValidation'
import ErrorHandler from 'utils/ErrorHandler'
import PaxLogin from 'services/AirBaltic/Pax/Login'
import moment from 'moment'
import gtmProfile from 'services/AirBaltic/GTM/Profile'
import conf from 'conf'

const ERRORS = {
  PROFILE_ALREADY_EXISTS: 'generic.errors.profileAlreadyExists',
  WRONG_OR_EXPIRED_CREATE_PROFILE_TOKEN: 'generic.errors.wrongOrExpiredToken',
  MEMBERSHIPNUMBER_WRONGNUMBER: 'generic.errors.wrongMembershipNumber',
  MEMBERSHIPNUMBER_DUPLICATEDNUMBER: 'generic.errors.alreadyExists'
}

const formModel = new Form()

const passWordRules = formModel.passwordValidationRuleMapping
delete passWordRules.notSameAsOldPassword

const formData = {
  day: formModel.day,
  month: formModel.month,
  year: formModel.year,
  form: formModel.form,
  ruleMapping: passWordRules,
  formErrors: formModel.errorMsg,
  errors: formModel.errors
}

const excluded = ['guardian', 'oldPassword', 'minorEmail', 'isMinorRegistration']

const defaultState = Object.assign({}, formData)

const validator = FormValidationUtils.initValidation(
  defaultState,
  formModel.validation,
  excluded
)

const RegisterProfileForm = {
  namespaced: true,
  state: defaultState,
  mutations: {
    setFirstName(state, value) {
      state.form.firstName = value
    },
    setLastName(state, value) {
      state.form.lastName = value
    },
    setTitle(state, value) {
      state.form.title = value
    },
    setDay(state, value) {
      state.day = value.trim()
    },
    setMonth(state, value) {
      state.month = value.trim()
    },
    setYear(state, value) {
      state.year = value.trim()
    },
    setDateOfBirth(state, value) {
      state.form.dateOfBirth = value
    },
    setEmail(state, value) {
      state.form.email = value
    },
    setIsABCMember(state, value) {
      state.form.isABCMember = value
    },
    setCardNumber(state, value) {
      state.form.abClub.card = value
    },
    setResidenceCountry(state, value) {
      state.form.residenceCountry = value
    },
    setLanguage(state, value) {
      state.form.language = value
    },
    setNotification(state, value) {
      state.form.notifications[value.platform].connected = value.connected
    },
    setPassword(state, value) {
      state.form.newPassword = value.trim()
    },
    setPasswordRepeat(state, value) {
      state.form.newPasswordRepeat = value.trim()
    },
    setGuardianCard(state, value) {
      state.form.guardian.card = value
    },
    setGuardianEmail(state, value) {
      state.form.guardian.email = value
    },
    setGuardianConsent(state, value) {
      state.form.guardian.consent = value
    },
    setIntCode(state, value) {
      state.form.phone.code = value
    },
    setPhoneNumber(state, value) {
      state.form.phone.number = value
    },
    setToken(state, value) {
      state.form.token = value
    },
    setIsMinorRegistration(state, value) {
      state.form.isMinorRegistration = value

      if (value) {
        state.formErrors.dateOfBirth.maxAge = 'reusable.dateOfBirth.validation.minor.maxAge'
        state.formErrors.dateOfBirth.minAge = 'reusable.dateOfBirth.validation.minor.minAge'
      }
    }
  },
  actions: {
    validateToken(context, token) {
      return new Promise((resolve, reject) => {
        SignUp.validateToken({ token })
          .then((response) => resolve(response.data))
          .catch((err) =>
            reject(ErrorHandler.getErrorTranslationKey(ERRORS, err.response))
          )
      })
    },
    setDob({ state, commit }) {
      let day = state.day
      let month = state.month
      const year = state.year

      if (day < 10 && day !== '') {
        day = day.padStart(2, '0')
      }
      if (month < 10 && day !== '') {
        month = month.padStart(2, '0')
      }

      if (state.day !== '' && state.month !== '' && year !== '') {
        commit('setDateOfBirth', `${year}-${month}-${day}`)
      } else {
        commit('setDateOfBirth', '')
      }
    },
    validateForm() {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state.form

        vState.$touch()

        !vState.$invalid
          ? resolve(true)
          : reject({
              type: 'validation',
              msg: 'register validation failed'
            })
      })
    },
    paxLogin({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const PaxLoginService = new PaxLogin()
        let paxUserData = {}

        paxUserData = data.userData
        PaxLoginService.doPimcoreLoginPing()
          .then(() => {
            dispatch('loginForm/setUserData', paxUserData, { root: true })
            dispatch('loginForm/doNativeLoginDataLayerPush', null, { root: true })
            resolve()
          })
          .catch((error) => reject(error))
      })
    },
    validateFormAndSubmit({ dispatch }) {
      return dispatch(`validateForm`)
        .then(() => dispatch('getDataAndSubmitForm'))
        .catch((err) => Promise.reject(err))
    },
    getDataAndSubmitForm({ dispatch }) {
      return dispatch('getData')
        .then((data) => dispatch('submitForm', data))
        .catch((e) => Promise.reject(e))
    },
    submitForm(context, data) {
      data = {
        ...data,
        loginTokenType: conf.jwtLoginTokenType
      }
      return SignUp.confirmCleanUser(data)
        .then((response) => {
          const refreshToken = response.data.refreshToken
          window.localStorage.setItem('refreshToken', refreshToken)
          gtmProfile.registerAction()
          return Promise.resolve(response.data)
        })
        .catch((err) => {
          return Promise.reject({
            type: 'submit',
            msg: ErrorHandler.getErrorTranslationKey(ERRORS, err.response)
          })
        })
    },
    getData({ state }) {
      return new Promise((resolve) => {
        const form = state.form

        const data = {
          token: form.token,
          dateOfBirth: moment(form.dateOfBirth, 'DD / MM / YYYY').format('YYYY-MM-DD'),
          gender: form.title,
          firstName: form.firstName,
          lastName: form.lastName,
          language: form.language.value,
          country: form.residenceCountry.value,
          password: form.newPassword,
          phoneCountry: form.phone.code.key,
          phoneDialingCode: form.phone.code.value,
          phoneNumber: form.phone.number,
          subscribedByEmail: form.notifications.newsletter.connected,
          smsSubscribe: form.notifications.smsDeals.connected,
          whatsAppSubscribe: form.notifications.whatsapp.connected,
        }

        if (form.abClub.card !== '' && form.isABCMember) {
          data.membershipNumber = form.abClub.card.replaceAll(' ', '')
        }

        resolve(data)
      })
    }
  },
  getters: {
    $form() {
      return Object.assign({}, validator.$v.state.form)
    },
    formErrors(state) {
      return state.formErrors
    }
  }
}

export default RegisterProfileForm
