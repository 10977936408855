import RegisterProfileStore from 'store/modules/Pages/ProfilePages/RegisterProfile'
import store from 'definitions/store'
import LocationService from 'services/AirBaltic/Location/Location'
import * as Sentry from '@sentry/vue'

const Init = {
    init(name) {
        if (!store[name]) {
            store.registerModule(name, RegisterProfileStore)

            Init.initDefaultValues(name)
        }
    },

    initDefaultValues(name) {
        store.commit(`${name}/generic/setPageTitle`, pageTitle)

        store.commit(`${name}/generic/setProfilePage`, profilePageUrl)

        if (typeof pageSections !== "undefined") {
            store.commit(`${name}/generic/setSections`, pageSections)
        }

        if (typeof termsAndConditions !== "undefined") {
            store.commit(`${name}/generic/setTerms`, termsAndConditions)
        }

        if (typeof signUpPage !== "undefined") {
            store.commit(`${name}/generic/setSignUpPage`, signUpPage)
        }

        if (typeof loginPage !== "undefined") {
            store.commit(`${name}/generic/setLoginPage`, loginPage)
        }

        store.commit('main/setFormNamespace', name, { root: true })

        if (typeof contentData_data_countries !== "undefined") {
            store.dispatch(`${name}/generic/setCountries`, contentData_data_countries._children, { root: true })
        }

        const service = new LocationService()

        service.getLanguageList()
          .then((data) => store.dispatch(`${name}/generic/setLanguages`, data, { root: true }))
          .catch((e) => Sentry.captureException(e))
    }
}

export default Init
