import axios from 'axios'
import conf from 'conf'
import get from 'lodash-es/get'

const Store = {
  state: {
    searchString: '',
    searchPageUrl: '',
    searchResults: [],
    currentPage: 1,
    totalPages: 1,
    totalResultCount: 21,
    suggestionCount: 3,
    searchResultsCount: 6,
    resultsPageVisible: false
  },
  mutations: {
    setSearchString(state, value) {
      state.searchString = value
    },
    setSearchResults(state, value) {
      state.searchResults = value
    },
    setSearchPageUrl(state, value) {
      state.searchPageUrl = value
    },
    setCurrentPage(state, value) {
      state.currentPage = value
    },
    setTotalPages(state, value) {
      state.totalPages = value
    },
    setTotalResultCount(state, value) {
      state.totalResultCount = value
    },
    setResultsPageVisible(state, value) {
      state.resultsPageVisible = value
    }
  },
  actions: {
    setSearchString({ commit, dispatch }, value) {
      commit('setSearchString', value)
      commit('setCurrentPage', 1)
      dispatch('getResults')
    },
    getResults({ state, dispatch }) {
      if (
        typeof conf.vertexProjectID !== 'undefined' &&
        typeof conf.vertexAppID !== 'undefined' &&
        typeof conf.vertexApiKey !== 'undefined' &&
        state.resultsPageVisible
      ) {
        dispatch('getSearchResults')
      }
    },
    getSearchResults({ commit, state, rootState }) {
      const selectedLang = get(rootState, 'main.selectedLang', 'en').toLowerCase()

      if (state.searchString !== '') {
        const PROJECT_ID = conf.vertexProjectID
        const APP_ID = conf.vertexAppID
        const API_KEY = conf.vertexApiKey

        const object = {
          servingConfig: `projects/${PROJECT_ID}/locations/eu/collections/default_collection/engines/${APP_ID}/servingConfigs/default_search`,
          query: state.searchString,
          pageSize: state.searchResultsCount,
          offset: state.searchResultsCount * state.currentPage - state.searchResultsCount,
          queryExpansionSpec: {
            condition: 'AUTO'
          },
          filter: `language: ANY("${selectedLang}")`,
          spellCorrectionSpec: {
            mode: 'AUTO'
          },
          safeSearch: true,
          contentSearchSpec: {
            snippetSpec: {
              returnSnippet: true
            }
          }
        }

        commit('main/setShowSmallLoader', true, { root: true })

        let url = `https://eu-discoveryengine.googleapis.com/v1alpha/projects/${PROJECT_ID}/locations/eu/collections/default_collection/engines/${APP_ID}/servingConfigs/default_search:searchLite?key=${API_KEY}`

        axios
          .post(url, object)
          .then((response) => {
            const total = parseInt(response.data.totalSize, 10)
            const pages = total !== 0 ? Math.round(total / state.searchResultsCount) : 1
            const items =
              typeof response.data.results !== 'undefined' ? response.data.results : []

            commit('setSearchResults', items)
            commit('setTotalPages', pages)
            commit('setTotalResultCount', total)
          })
          .catch(() => commit('setSearchResults', []))
          .finally(() => commit('main/setShowSmallLoader', false, { root: true }))
      }
    }
  },
  namespaced: true
}

export default Store
