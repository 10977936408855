<template>
  <div class="accordion-w-menu-content main-content wide">
    <div class="wrap">
      <div
        v-if="backgroundUrl"
        :style="$_ImageHelpers_getBackgroundImage(backgroundUrl)"
        class="lead-img"
      />

      <sidebar-left
        v-if="isDesktop"
        :press-contacts="pressContacts"
      />

      <div class="accordion-w-menu-main-block">
        <h1 :class="{ 'with-print-btn': printEnabled }">{{ pageTitle }}</h1>
        <print-button
          v-if="printEnabled"
          class="filled"
        />
        <p
          v-dompurify-html="mainIntro"
          class="accordion-w-menu-lead-text"
        />

        <div class="accordion-w-menu-second-intro-text">
          <div
            v-if="secondIntro !== ''"
            v-dompurify-html="secondIntro"
            v-inlineModal
          />
        </div>

        <sixt-widget
          v-if="showSixtWidget"
          :title="sixtWidgetTitle"
          :search-only="searchOnlySixt"
        />

        <div class="accordion-w-menu-second-intro-text">
          <div
            v-if="additionalContent !== ''"
            v-dompurify-html="additionalContent"
            v-inlineModal
          />
        </div>

        <pre-accordion-image
          v-if="preAccordionImages.length > 0"
          :data="preAccordionImages"
        />

        <div
          v-for="(item, index) in accordionItems"
          :key="index"
          class="content-item"
        >
          <div v-if="item[0] && item[0].type === 'cookieBotList'">
            <cookie-bot-list-widget />
          </div>

          <div
            v-if="item[0] && item[0].type === 'accordion'"
            class="accordion-w-menu-accordion"
          >
            <accordion :accordion-items="item" />
          </div>

          <sherpa-widget
            v-else-if="item[0] && item[0].type === 'sherpaWidget'"
            :appearance="item[0].appearance"
            :placement="item[0].placement"
            :show-destination-selector="item[0].showDestinationSelector"
            :show-travel-restriction="item[0].showTravelRestriction"
          />

          <sherpa-map-widget
            v-else-if="item[0] && item[0].type === 'sherpaMapWidget'"
            :placement="item[0].placement"
          />

          <app-buttons-widget v-else-if="item[0] && item[0].type === 'appButtons'" />

          <plusgrade-widget v-else-if="item[0] && item[0].type === 'plusgradeWidget'" />

          <simple-table
            v-else-if="item.type === 'table'"
            :table-data="JSON.parse(item.tableData)"
          />

          <template v-else>
            <div
              v-for="(textItem, itemIndex) in item"
              :key="itemIndex"
            >
              <a
                :id="textItem.anchor"
                class="anchor"
              />
              <div
                v-dompurify-html="textItem.text"
                v-inlineModal
              />
            </div>
          </template>
        </div>

        <component
          :is="'script'"
          v-if="showJsonLD"
          type="application/ld+json"
          v-html="jsonld"
        />

        <div class="terms-wrap">
          <terms-and-conditions
            v-if="termsAndConditionsVisible"
            :content="termsAndConditions.content"
            :title="termsAndConditions.title"
          />
        </div>
      </div>

      <sidebar-left
        v-if="isMobile || isTablet"
        :press-contacts="pressContacts"
      />
    </div>
  </div>
</template>

<script>
  import Sidebar from 'components/Sidebar/Sidebar'
  import PrintButton from 'components/Buttons/PrintButton'
  import InlineModal from 'utils/directives/InlineModal'
  import ScrollUtils from 'utils/scrollUtils'
  import { mapState, mapGetters } from 'vuex'
  import ImageHelpers from 'utils/mixins/ImageHelpers'

  const SixtWidget = () =>
    import(/* webpackChunkName: "widget-sixt" */ 'components/Widgets/Sixt')

  const TermsAndConditions = () =>
    import(/* webpackChunkName: "terms-conditions" */ 'components/TermsAndConditions')

  const PreAccordionImage = () =>
    import(
      /* webpackChunkName: "pre-accordion-image" */ 'components/Accordion/PreAccordionImage'
    )

  const Accordion = () =>
    import(/* webpackChunkName: "accordion-item" */ 'components/Accordion/Accordion')

  const SimpleTable = () =>
    import(/* webpackChunkName: "simple-table" */ 'components/Tables/SimpleTable')

  const SherpaWidget = () =>
    import(/* webpackChunkName: "widget-sherpa" */ 'components/Widgets/Sherpa')

  const SherpaMapWidget = () =>
    import(/* webpackChunkName: "widget-sherpa-map" */ 'components/Widgets/SherpaMap')

  const AppButtonsWidget = () =>
    import(/* webpackChunkName: "widget-app-buttons" */ 'components/Widgets/AppButtons')

  const CookieBotListWidget = () =>
    import(
      /* webpackChunkName: "widget-cookiebot-list" */ 'components/Widgets/CookieBotList'
    )
  const PlusgradeWidget = () =>
    import(/* webpackChunkName: "widget-plusgrade" */ 'components/Widgets/Plusgrade')


  export default {
    components: {
      'sidebar-left': Sidebar,
      Accordion,
      PreAccordionImage,
      PrintButton,
      SimpleTable,
      TermsAndConditions,
      SherpaWidget,
      SherpaMapWidget,
      AppButtonsWidget,
      SixtWidget,
      CookieBotListWidget,
      PlusgradeWidget
    },
    directives: {
      InlineModal
    },
    mixins: [ImageHelpers],
    computed: {
      ...mapState('accordionPage', [
        'pageLeadImgDesktop',
        'preAccordionImages',
        'pageLeadImgTablet',
        'pageLeadImgMobile',
        'pageTitle',
        'mainIntro',
        'secondIntro',
        'accordionItems',
        'pressContacts',
        'printEnabled',
        'tableData',
        'termsAndConditionsVisible',
        'termsAndConditions',
        'showJsonLD',
        'showSixtWidget',
        'sixtWidgetTitle',
        'searchOnlySixt',
        'additionalContent'
      ]),
      ...mapGetters('device', ['isDesktop', 'isMobile', 'isTablet']),
      backgroundUrl() {
        return this.$_ImageHelpers_responsiveImage({
          desktop:
            typeof this.pageLeadImgDesktop !== 'undefined'
              ? this.pageLeadImgDesktop.source
              : '',
          tablet:
            typeof this.pageLeadImgTablet !== 'undefined'
              ? this.pageLeadImgTablet.source
              : '',
          mobile:
            typeof this.pageLeadImgMobile !== 'undefined'
              ? this.pageLeadImgMobile.source
              : ''
        })
      },
      parsedAccordionItems() {
        return this.accordionItems.filter((item) => item[0].type === 'accordion')
      },
      jsonld() {
        const entries = this.parsedAccordionItems.flatMap((accordion) => {
          return accordion.flatMap((item) => ({
            '@type': 'Question',
            name: item.title,
            acceptedAnswer: {
              '@type': 'Answer',
              text: item.text.replaceAll(
                'href="/',
                'href="' + window.location.origin + '/'
              )
            }
          }))
        })

        return {
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: entries
        }
      }
    },
    mounted() {
      setTimeout(() => this.scroll(), 300)
    },
    methods: {
      openPanel(target) {
        target.parentNode.classList.toggle('current')
      },
      scroll() {
        const scrollDelay = 300
        const scrollInterval = 300

        window.history.scrollRestoration = 'manual'

        const hash = window.location.hash.replace('#', '')
        const element = this.$el.querySelector(`a[id="${hash}"]`)

        if (!element) {
          return
        }

        const headerElement = document.getElementsByClassName('header-wrap-white')

        if (!headerElement.length) {
          return
        }

        const headerHeight = !this.isDesktop ? headerElement[0].offsetHeight : 0
        const current = document.documentElement.scrollTop
        const elementTop = element.getBoundingClientRect().top

        if (element.getAttribute('data-type') === 'accordion') {
          this.openPanel(element.getElementsByClassName('panel-title-content')[0])
        }
        setTimeout(() => {
          ScrollUtils.smoothScroll(current + elementTop - headerHeight, scrollInterval)
        }, scrollDelay)
      }
    }
  }
</script>

<style
  lang="scss"
  rel="stylesheet/scss"
  scoped
>
  @import '~assets/styles/common.scss';

  .anchor {
    display: block;
    position: relative;
    top: -66px;
    visibility: hidden;

    @include bp-mf-tablet {
      top: -148px;
    }
  }

  .accordion-w-menu-content {
    @include bp-tablet-only {
      padding: 0 0 20px;
    }

    @include bp-mf-desktop {
      padding: 0 0 72px;
    }

    .wrap {
      @include clearfix();

      @include bp-tablet {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .lead-img {
    width: 100%;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @include bp-mf-tablet {
      height: 320px;
    }
  }

  .left-menu,
  .accordion-w-menu-main-block {
    margin-top: 40px;

    @include bp-tablet {
      margin-top: 0;
    }
  }

  .accordion-w-menu-main-block {
    position: relative;
    float: left;
    vertical-align: top;
    width: 66%;

    @media print {
      margin-top: 0;
    }

    @include bp-tablet-only {
      margin-top: 22px;
      padding: 0 26px;
    }

    @include bp-tablet {
      order: 1;
      width: 100%;
    }

    @include bp-mobile {
      padding: 25px 23px 0;
    }

    h1 {
      @include h1Style();

      @include bp-mobile {
        word-wrap: break-word;
      }

      &.with-print-btn {
        padding-right: 200px;

        @include bp-mobile {
          padding-right: 0;
        }
      }
    }

    ::v-deep h2 {
      @include h2Style();
    }

    ::v-deep h3 {
      @include h3Style();
    }

    ::v-deep p {
      @include body16Style();

      &:not(.accordion-w-menu-lead-text) {
        @include body16Style();
        line-height: 1.5;
      }

      &.accordion-w-menu-lead-text {
        @include introStyle();
      }

      a:not(.js-inline-button) {
        color: $blue-medium;

        &:hover {
          color: $blue-light;
        }

        &[href^='#'] {
          display: inline-block;
          margin: 0 8px 8px 0;
          padding: 0 12px;
          background-color: $blue-grey-light;
          border-radius: 4px;
          font-size: 14px;
          line-height: 40px;
          text-decoration: none;

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            background-color: $gray-light-hover;
            color: $blue-medium;
          }
        }
      }

      img {
        width: 100%;
      }

      .js-inline-button {
        font-size: rem(15, 16);
      }
    }

    ::v-deep strong {
      font-weight: 500;
    }
  }

  ::v-deep ul {
    padding-top: 3px;
    list-style: none;

    @include bp-tablet {
      padding-top: 0;
    }

    a {
      color: $blue-medium;

      &:hover {
        color: $blue-light;
      }
    }

    + p {
      margin-top: 18px;

      @include bp-mobile {
        margin-top: 15px;
      }
    }
  }

  .accordion-w-menu-second-intro-text /deep/ li,
  .content-item /deep/ li {
    position: relative;
    padding-left: 35px;

    @include body16Style();

    @include bp-tablet {
      padding-left: 22px;
    }

    &:not(:last-child) {
      margin-bottom: 8px;

      @include bp-tablet-only {
        margin-bottom: 7px;
      }

      @include bp-mobile {
        margin-bottom: 9px;
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $green-base;
    }
  }

  .accordion-w-menu-second-intro-text,
  .content-item {
    ::v-deep ul {
      ul > li {
        font-size: inherit !important;
      }
    }
  }

  .accordion-w-menu-accordion {
    margin: 20px 0 45px;

    @include bp-tablet-only {
      margin: 20px 0 35px;
    }

    @include bp-mobile {
      margin: 15px 0 20px -23px;
      width: calc(100% + 46px);
      border-bottom: 1px solid $grey-border;
    }
  }

  .accordion-w-menu-second-intro-text {
    + div:not(.terms-and-conditions-block) {
      padding-top: 20px;
    }

    ::v-deep img {
      width: 100%;
    }
  }

  .terms-wrap {
    order: 1;

    .terms-and-conditions-block {
      @include bp-mf-desktop {
        position: relative;
        vertical-align: top;
      }
    }
  }

  ::v-deep {
    .hideMobile {
      display: none;

      @include bp-mf-tablet {
        display: inline;
      }
    }

    .hideTablet {
      @include bp-mf-tablet {
        display: none;
      }

      @include bp-mf-desktop {
        display: inline;
      }
    }

    .hideDesktop {
      @include bp-mf-desktop {
        display: none;
      }
    }
  }
</style>
