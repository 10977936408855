var VolDateChange = /** @class */ (function () {
    function VolDateChange() {
    }
    VolDateChange.validationErrors = {
        NO_FLIGHTS: 'volDateChange.errors.noFlights',
        CHANGE_NOT_PERMITTED: 'volDateChange.errors.noFlights',
        CANNOT_REPRICE: 'volDateChange.errors.cannotReprice',
        REPRICER_ERROR: 'volDateChange.errors.cannotReprice',
        VALIDATION_FAILED: 'volDateChange.errors.cannotReprice',
        SYSTEM_ERROR: 'volDateChange.errors.systemFailure',
        FLIGHT_NOT_BOOKED: 'volDateChange.errors.flightNotAvailable',
        MARKING_PREBOOKED_FLIGHT_FAILED: 'volDateChange.errors.prebookError',
        PAYMENT_TRANSITION_FAILED: 'volDateChange.errors.paymentGatewayError',
        TOO_MANY_REQUESTS: 'volDateChange.errors.tooManyRequests'
    };
    return VolDateChange;
}());
export default VolDateChange;
