import Api from 'services/AirBaltic/Api'
import AuthorizedApi from 'services/AirBaltic/AuthorizedApi'
import ErrorHandler from 'utils/ErrorHandler'

const ERRORS = {
  WRONG_OR_EXPIRED_CHANGE_EMAIL_TOKEN: 'generic.errors.wrongOrExpiredChangeEmailToken',
  SAME_EMAIL: 'generic.errors.sameEmail',
  EMAIL_ALREADY_SENT: 'generic.errors.emailChangeAlreadySent'
}

class EmailChange {
  confirm (data) {
    const refreshToken = window.localStorage.getItem('refreshToken')
    if (refreshToken) {
      data = {
        ...data,
        refreshToken
      }
    }
    return new Api().post('/profile/v2/emailChange/confirm', data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(this.getErrors(error.response)))
  }

  sendEmail (data) {
    return new AuthorizedApi().post('/profile/emailChange/sendEmail', data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(this.getErrors(error.response)))
  }

  getErrors (response) {
    return ErrorHandler.getErrorTranslationKey(ERRORS, response)
  }
}

export default EmailChange
