import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import { alphaWithSpaces, onlyLatinWithNumbersAndSpecial } from 'utils/validators'
import { maxLength, required, email, requiredIf } from 'vuelidate/lib/validators'
import moment from 'moment'
import Passengers from 'store/modules/Pages/Claims/Sections/Passengers'
import Flights from 'store/modules/Pages/Claims/Sections/Flights'
import BankInfo from 'store/modules/Pages/Claims/Sections/BankInfo'
import Baggage from 'store/modules/Pages/Claims/Sections/Baggage'
import Files from 'store/modules/Pages/Claims/Form/FilesOnBehalf'
import ClaimsService from 'services/AirBaltic/Forms/Claims'
import divolteClaims from 'services/AirBaltic/Divolte/Claims'
import PassengerModel from 'models/Claims/Passenger'
import FlightModel from 'models/Forms/Flights'

const formErrors = {
  contactEmail: {
    required: 'reusable.email.validation.missing',
    email: 'reusable.email.validation.invalid'
  },
  contactName: {
    alphaWithSpaces: 'reusable.firstName.validation.alphaWithSpaces',
    required: 'reusable.firstName.validation.required'
  },
  contactSurname: {
    alphaWithSpaces: 'reusable.lastName.validation.alphaWithSpaces',
    required: 'reusable.lastName.validation.required'
  },
  additionalInfo: {
    maxLength: 'reusable.request.validation.maxLength'
  },
  onOwnBehalf: {
    required: 'claims.onBehalf.validation.required',
    manyPaxYes: 'claims.onBehalf.validation.manyPaxYes'
  },
  contactAgency: {
    onlyLatinWithNumbersAndSpecial: 'reusable.agency.validation.onlyLatinWithNumbersAndSpecial',
    maxLength: 'reusable.agency.validation.maxLength'
  }
}

const validator = new Vue({
  mixins: [validationMixin],
  computed: {
    state() {
      return defaultState
    }
  },
  validations() {
    return {
      state: {
        additionalInfo: {
          maxLength: maxLength(2000)
        },
        contactEmail: {
          required,
          email
        },
        contactName: {
          alphaWithSpaces,
          required: requiredIf(
            () => this.state.passengers.passengers.length > 1
          )
        },
        contactSurname: {
          alphaWithSpaces,
          required: requiredIf(
            () => this.state.passengers.passengers.length > 1
          )
        },
        onOwnBehalf: {
          required,
          manyPaxYes: (value) => {
            let isManyPax =
              (this.state.isGroup && this.state.passengers.passengers.length > 1)
              || this.state.passengers.selectedPassengers.length > 1
            return !(value === 'yes' && isManyPax)
          }
        },
        contactAgency: {
          onlyLatinWithNumbersAndSpecial,
          maxLength: maxLength(20)
        }
      }
    }
  }
})

const defaultState = {
  categoryType: '',
  additionalInfo: '',
  preferredLanguage: 'en',
  onOwnBehalf: '',
  contactEmail: '',
  contactName: '',
  contactSurname: '',
  contactAgency: '',
  paxLastNameDisabled: false,
  paxWithSameSurname: false,
  paxFromPnr: null,
  isSinglePaxPnr: false,
  isGroup: false,
  formErrors: formErrors
}

const DefaultForm = {
  modules: {
    passengers: Passengers,
    flights: Flights,
    bankInfo: BankInfo,
    baggage: Baggage,
    onBehalfDocuments: Files
  },
  state: defaultState,
  mutations: {
    setAdditionalInfo(state, value) {
      state.additionalInfo = value
    },
    setPaxWithSameSurname(state, value) {
      state.paxWithSameSurname = value
    },
    setPreferredLang(state, value) {
      state.preferredLanguage = value
    },
    setEmail(state, value) {
      state.contactEmail = typeof value === 'string' ? value.replace(/ /g, '') : ''
    },
    setFirstName(state, value) {
      state.contactName = value
    },
    setLastName(state, value) {
      state.contactSurname = value
    },
    setOnBehalf(state, value) {
      state.onOwnBehalf = value
    },
    setAgencyRef(state, value) { 
      state.contactAgency = value
    },
    setCategoryType(state, value) {
      state.categoryType = value
    },
    setPaxLastNameDisabled(state, value) {
      state.paxLastNameDisabled = value
    },
    setPaxFromPnr(state, value) {
      state.paxFromPnr = value
    },
    setIsSinglePaxPnr(state, value) {
      state.isSinglePaxPnr = value
    },
    setIsGroup (state, value) {
      state.isGroup = value
    }
  },

  actions: {
    submitForm({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('touchEverything')
          .then(() => {
            dispatch('sendRequest')
              .then(() => resolve())
              .catch((e) => reject(e))
          })
          .catch((e) => {
            reject(e)
          })
      })
    },

    clearForm({ commit, dispatch }) {
      validator.$v.state.$reset()
      commit('setCategoryType', '')
      commit('setAdditionalInfo', '')
      commit('setPreferredLang', 'en')
      commit('setEmail', '')
      commit('setFirstName', '')
      commit('setLastName', '')
      commit('setAgencyRef', '')
      commit('setIsGroup', false)
      commit('setIsSinglePaxPnr', false)

      dispatch('claims/form/passengers/clearForm', null, { root: true })
      dispatch('claims/form/flights/clearForm', null, { root: true })
      dispatch('claims/form/bankInfo/clearForm', null, { root: true })
      dispatch('claims/form/baggage/clearForm', null, { root: true })
    },

    touch() {
      return new Promise((resolve, reject) => {
        const vState = validator.$v.state
        vState.$touch()

        !vState.$invalid ? resolve(true) : reject('default form')
      })
    },

    touchEverything({ state, dispatch }) {
      const rules = [
        dispatch('touch'),
        dispatch('claims/form/flights/touch', null, { root: true }),
        dispatch('claims/form/bankInfo/touch', null, { root: true }),
        dispatch('claims/form/passengers/touch', null, { root: true }),
        dispatch('claims/files/touch', null, { root: true })
      ]

      if (state.onOwnBehalf) {
        rules.push(dispatch('claims/form/onBehalfDocuments/touch', null, { root: true }))
      }
      if (state.categoryType === 'baggage') {
        rules.push(dispatch('claims/form/baggage/touch', null, { root: true }))
      }

      return Promise.all(rules)
        .then(() => Promise.resolve(true))
        .catch((e) => Promise.reject([e]))
    },

    sendRequest({ state, getters, rootState, commit, dispatch }) {
      const postObj = {
        bookingRef: rootState.claims.pnr.bookingRef,
        contact: getters.contactsForApi,
        passengers: getters.paxForApi,
        flightList: getters.flightsForApi,
        message: state.additionalInfo,
        type: rootState.claims.activeStep.key,
        agencyRefNo: state.contactAgency
      }

      if (state.onOwnBehalf === 'no') {
        postObj.contact.behalfOfOtherPerson = true
      }

      if (state.categoryType === 'baggage') {
        postObj.baggageNumber = state.baggage.pir
        postObj.baggageReceived = state.baggage.hasReceived === 'yes'

        if (state.baggage.receiptSectionNeeded) {
          postObj.baggagePurchaseReceiptAvailable =
            state.baggage.receiptAvailable === 'yes'
        }
      }

      if (state.bankInfo.reimbursement) {
        postObj.reimbursement = getters['bankInfo/bankInfoData']
      }

      if (!state.bankInfo.reimbursementTypes.skipValidation) {
        postObj.compensationAccordingToEuRegulation =
          state.bankInfo.reimbursementTypes.standardReimbursement
        postObj.expensesReimbursement =
          state.bankInfo.reimbursementTypes.expensesReimbursement
      }

      return new ClaimsService(state.categoryType)
        .submitForm(postObj)
        .then((response) => dispatch('goToFilesForm', response.data.fileUploadToken))
        .catch((error) => Promise.reject(error))
    },

    goToFilesForm({ state, commit, dispatch }, token) {
      dispatch('divoltePush')
      commit('claims/files/setToken', token, { root: true })
      let onBehalfDocs = state.onBehalfDocuments?.files || []

      dispatch('claims/files/submitForm', onBehalfDocs, { root: true }).then((res) => {
        if (!res) {
          commit('claims/setActiveStep', { key: 'files' }, { root: true })
        } else {
          dispatch('claims/showSuccessModal', null, { root: true })
        }
      })
    },

    divoltePush({ state }) {
      divolteClaims.divoltePush(state)
    },
    getPaxData({ dispatch, rootState, commit }, passengers) {
      const paxData = []
      const paxWithLastNameFromPnr = passengers.filter(
        (pax) => pax.surname === rootState.claims.pnr.form.surname
      )
      commit('setPaxFromPnr', new PassengerModel(paxWithLastNameFromPnr[0]))
      const isGroup = rootState.claims.pnr.isGroup
      const sameSurname = paxWithLastNameFromPnr.length > 1

      commit('claims/form/setPaxWithSameSurname', sameSurname, { root: true })
      commit('setIsGroup', isGroup)

      if (isGroup) {
        if (sameSurname) {
          paxData.push(new PassengerModel())
        } else {
          let pax = {
            ...paxWithLastNameFromPnr[0],
            selected: true
          }
          paxData.push(new PassengerModel(pax))
        }
      } else {
        passengers.forEach((pax, index) => {
          const passenger = Object.assign({ id: index }, pax)
          if (passenger.givenName !== null) {
            paxData.push(new PassengerModel(passenger))
          }
        })
        if (passengers.length === 1) {
          commit('setIsSinglePaxPnr', true)
        }
      }

      if (paxData.length) {
        dispatch('filterPaxWithCheckbox', paxData)
      }
    },

    filterPaxWithCheckbox({ commit }, paxData) {
      commit('claims/form/passengers/setPassengers', paxData, { root: true })
    },

    getFlightData({ commit }, flights) {
      const flightData = []

      flights.forEach((flight) => {
        flightData.push(new FlightModel(flight))
      })

      commit('claims/form/flights/setHasFlights', !!flightData.length, { root: true })
      commit('claims/form/flights/setFlights', flightData, { root: true })
    }
  },

  getters: {
    $v() {
      return Object.assign({}, validator.$v.state)
    },

    paxForApi(state) {
      const passengers = []

      state.passengers.passengers.forEach((pax) => {
        if ((pax.selected || state.isSinglePaxPnr) && pax.firstName && pax.lastName) {
          passengers.push({
            firstName: pax.firstName,
            lastName: pax.lastName
          })
        }
      })

      return passengers
    },

    flightsForApi(state) {
      const data = state.flights.hasFlights
          ? state.flights.selectedFlights
          : state.flights.flights

      return data.map((flight) => ({
        flightNumber: flight.flightNumber.trim().toUpperCase(),
        flightDate: moment(
          `${flight.flightYear}-${flight.flightMonth}-${flight.flightDay}`,
          'YYYY-MM-DD'
        ).format('YYYY-MM-DD')
      }))
    },

    contactsForApi(state) {
      let contactFirstName = state.contactName
      let contactLastName = state.contactSurname
      if (state.contactName.trim().length === 0) {
        let contactPax = state.passengers.passengers[0]
        if (!state.filledOnBehalf) {
          if (state.paxFromPnr) {
            contactPax = state.paxFromPnr
          }
        }

        contactFirstName = contactPax.firstName
        contactLastName = contactPax.lastName
      }
      return {
        firstName: contactFirstName,
        lastName: contactLastName,
        language: state.preferredLanguage.toUpperCase(),
        email: state.contactEmail
      }
    }
  },

  namespaced: true
}

export default DefaultForm
